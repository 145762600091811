import useFlags from './useFlags'
import useSettings from './useSettings'
import { createContext, ReactNode, useContext } from 'react'
import { z } from 'zod'

const allFeatures = z.enum([
  'dev-hide-stock-logo',
  'dev-lock-scroll',
  'dev-simulate-expired-session',
  'dev-suppress-page-animation',
  'disable-auto-skip',
  'allow-crypto',
  'allow-trading',
  'hide-exit-button',
  'hide-desktop-back-button',
  'plaid-oauth-redirect-flow',
  'dev-force-contribution-state-no-contribution',
  'dev-force-contribution-state-account-disconnected',
  'dev-force-contribution-state-account-missing-bank-list-empty',
  'dev-force-contribution-state-account-missing-bank-list-exist',
  'dev-force-contribution-state-requested',
  'dev-force-contribution-state-scheduled',
  'dev-force-contribution-state-processing',
  'dev-force-contribution-state-account-insufficient-balance',
  'dev-force-contribution-state-canceled',
  'dev-force-contribution-state-expired',
  'dev-force-contribution-state-failed',
  'dev-force-contribution-state-account-processing',
  'dev-force-contribution-state-account-balance-update-required',
  'dev-force-state-account-pending',
  'dev-enable-onboarding-confirm-errors',
  'disable-contribution-page',
  'disable-withdrawal-page',
  'disable-linking-new-account',
  'disable-self-directed',
  'disable-email-requests',
  'disable-complete-account-on-error',
  'disable-settings-goals-to-support',
  'disable-settings-industries-to-avoid',
  'disable-settings-restricted-stocks',
  'enable-corp-dashboard',
  'enable-kyc-retry',
  'disable-disclosures-footer',
  'disable-onboarding-confirmation',
  'allow-retirement-account',
  'disable-copy-agreement-link',
  'allow-mmf',
  'advisor-assignment-enabled',
  'allow-high-yield-cash-account',
  'disable-general-investment-account',
  'multi-portfolio-dashboard',
  'allow-self-directed-treasury',
  'allow-self-directed-mmf',
])

export type Feature = z.infer<typeof allFeatures>

/**
 * Features let enable/disable some part of the app dynamically.
 * We get features from next sources (priority first):
 *  - LaunchDarkly flags
 *  - `features` array from module config
 * Note, LaunchDarkly flags can override `features` from module config.
 *
 * Expected flags from LaunchDarkly:
 *  - boolean: true | false
 *  - string: 'unset' | 'on' | 'off'
 *
 * String flag is useful to explicitly disable feature (not possible with boolean flag).
 */
const FeaturesContext = createContext<string[]>([])

export default function useFeature(featureId: Feature): boolean {
  const context = useContext(FeaturesContext)
  const flags = useFlags()

  if (!allFeatures.safeParse(featureId).success) {
    throw new TypeError(`Invalid feature: ${featureId}`)
  }

  // 2. Check if LaunchDarkly has boolean feature turned on
  if (flags[featureId]) {
    return true
  }

  // 3. Check if LaunchDarkly has string feature turned on
  if (flags[featureId] === 'on') {
    return true
  }

  // 3. Check if LaunchDarkly has string feature turned off
  if (flags[featureId] === 'off') {
    return false
  }

  // 4. Check if module config has the feature enabled
  if (context.includes(featureId)) {
    return true
  }

  // 5. None of the above applies, feature is disabled
  return false
}

interface FeatureProps {
  children: ReactNode
}

export function FeaturesProvider(props: FeatureProps) {
  const { features } = useSettings()
  return (
    <FeaturesContext.Provider value={features}>
      {props.children}
    </FeaturesContext.Provider>
  )
}
