import {
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  ComponentProps,
  useRef,
  useState,
} from 'react'
import classNames from 'classnames'
import { WarningCircle } from '@phosphor-icons/react'

export interface TextFieldProps extends ComponentProps<'div'> {
  className?: string
  name?: string
  forceFilledState?: boolean
  forceActiveState?: boolean
  children: ReactNode | ((filled: boolean) => ReactNode)
  errorMessage?: string
  infoMessage?: string
  hideErrorIcon?: Boolean
  autoFocus?: boolean
  disableLabelMove?: boolean
  disabled?: boolean
}

export interface TextFieldRef {
  reset: () => void
}

const TextField = forwardRef<TextFieldRef, TextFieldProps>(
  (props, incomingRef) => {
    const {
      className,
      forceFilledState = false,
      forceActiveState,
      children,
      errorMessage,
      infoMessage,
      hideErrorIcon,
      disabled,
      autoFocus = false,
      disableLabelMove = false,
      ...restProps
    } = props
    const ref = useRef<HTMLDivElement>(null)
    const [filled, setFilled] = useState(false)
    const [focused, setFocused] = useState(false)

    const classList = classNames('TextField', className, {
      'TextField--filled': filled || forceFilledState,
      'TextField--error': errorMessage,
      'TextField--disabled': disabled,
      'TextField--focused': focused,
      'TextField--active': forceActiveState,
      'TextField--disable-label-move': disableLabelMove && !forceFilledState,
    })

    const updateFilledState = useCallback(() => {
      const input = ref.current?.querySelector('input')
      if (input) {
        setFilled(Boolean(input.value))
      }
    }, [])

    useEffect(() => {
      if (!forceFilledState) {
        setFilled(false)
      }
    }, [forceFilledState, setFilled])

    useEffect(() => {
      const input = ref.current?.querySelector('input')

      function onFocus() {
        setFocused(true)
      }

      function onBlur() {
        setFocused(false)
      }

      if (autoFocus) {
        input?.focus()
      }

      input?.addEventListener('keyup', updateFilledState, false)
      input?.addEventListener('change', updateFilledState, false)
      input?.addEventListener('focus', onFocus, false)
      input?.addEventListener('blur', onBlur, false)
      updateFilledState()

      return () => {
        input?.removeEventListener('keyup', updateFilledState, false)
        input?.removeEventListener('change', updateFilledState, false)
        input?.removeEventListener('focus', onFocus, false)
        input?.removeEventListener('blur', onBlur, false)
      }
    }, [autoFocus, updateFilledState])

    useImperativeHandle(incomingRef, () => ({
      reset: () => {
        setTimeout(updateFilledState, 20)
      },
    }))

    return (
      <div className="relative">
        <div ref={ref} className={classList} {...restProps}>
          {typeof children === 'function' ? children(filled) : children}
          {errorMessage && !hideErrorIcon && (
            <WarningCircle className="TextField-alert size-5" weight="fill" />
          )}
        </div>
        {errorMessage && <div className="TextField-error">{errorMessage}</div>}
        {infoMessage && <div className="TextField-info">{infoMessage}</div>}
      </div>
    )
  }
)

function TextFieldLabel(props: ComponentProps<'label'>) {
  const { className, ...restProps } = props

  return (
    <label
      className={classNames('TextField-label', className)}
      {...restProps}
    />
  )
}

const CompoundTextField = Object.assign({}, TextField, {
  Label: TextFieldLabel,
})

export default CompoundTextField
