import { useIntl } from 'react-intl'
import classNames from 'classnames'

interface DeltaAbsNumberProps {
  value: number
  disabled?: boolean
  alwaysBlue?: boolean
}

export function DeltaAbsNumber(props: DeltaAbsNumberProps) {
  const { value, disabled, alwaysBlue } = props
  const intl = useIntl()
  const negative = value < 0
  const text = intl.formatNumber(value, {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    signDisplay: 'always',
  })

  return (
    <span
      className={classNames('DeltaAbsNumber', {
        'DeltaAbsNumber--disabled': disabled,
        'DeltaAbsNumber--negative': !disabled && negative && !alwaysBlue,
      })}
    >
      {text}
    </span>
  )
}

interface DeltaRelNumberProps {
  value: number
  disabled?: boolean
  alwaysBlue?: boolean
}

export function DeltaRelNumber(props: DeltaRelNumberProps) {
  const { value, disabled, alwaysBlue } = props
  const intl = useIntl()
  const negative = value < 0
  const text = intl.formatNumber(value, {
    style: 'percent',
    minimumFractionDigits: 2,
    signDisplay: 'never',
  })

  return (
    <span
      className={classNames('DeltaRelNumber', {
        'DeltaRelNumber--disabled': disabled,
        'DeltaRelNumber--negative': !disabled && negative && !alwaysBlue,
      })}
    >
      {text}
    </span>
  )
}

interface DeltaNumberProps {
  deltaAbsValue: number
  deltaRelValue: number
  disabled?: boolean
  alwaysBlue?: boolean
}

export default function DeltaNumber(props: DeltaNumberProps) {
  let { deltaAbsValue, deltaRelValue, disabled, alwaysBlue } = props
  let abs = Math.abs(deltaAbsValue) >= 0.01 ? deltaAbsValue : 0
  let rel = Math.abs(deltaAbsValue) >= 0.01 ? deltaRelValue : 0

  return (
    <div className="DeltaNumber">
      <DeltaAbsNumber value={abs} disabled={disabled} alwaysBlue={alwaysBlue} />
      <DeltaRelNumber value={rel} disabled={disabled} alwaysBlue={alwaysBlue} />
    </div>
  )
}
