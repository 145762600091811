import { z } from 'zod'

const ThemeFont = z.object({
  'family-primary': z.string().default('atomic-default-font'),
  'weight-primary-regular': z.string().default('400'),
  'weight-primary-bold': z.string().default('500'),
})

const ThemeColors = z.object({
  neutral0: z.string().default('#FFFFFF'),
  neutral5: z.string().default('#FDFDFD'),
  neutral10: z.string().default('#F5F6F7'),
  neutral15: z.string().default('#E8EAED'),
  neutral25: z.string().default('#DBDDE2'),
  neutral50: z.string().default('#C2C6CF'),
  neutral60: z.string().default('#A8AEBB'),
  neutral75: z.string().default('#9298A8'),
  neutral100: z.string().default('#616A81'),
  neutral125: z.string().default('#454F6A'),
  neutral150: z.string().default('#303C5A'),
  neutral200: z.string().default('#0C1A3D'),

  primary5: z.string().default('#FFF'),
  primary10: z.string().default('#F5F6F7'),
  primary25: z.string().default('#E8EAED'),
  primary40: z.string().default('#DBDDE2'),
  primary50: z.string().default('#C2C6CF'),
  primary75: z.string().default('#A8AEBB'),
  primary100: z.string().default('#0C1A3D'),
  primary125: z.string().default('#303C5A'),
  primary150: z.string().default('#454F6A'),
  primary175: z.string().default('#616A81'),
  primary200: z.string().default('#9298A8'),

  secondary5: z.string().default('#FFF9F9'),
  secondary10: z.string().default('#FFE8EA'),
  secondary25: z.string().default('#FFC9CE'),
  secondary40: z.string().default('#FC9AA2'),
  secondary50: z.string().default('#F26874'),
  secondary75: z.string().default('#E03A48'),
  secondary100: z.string().default('#D10A2B'),
  secondary125: z.string().default('#BE0927'),
  secondary150: z.string().default('#94071F'),
  secondary175: z.string().default('#730618'),
  secondary200: z.string().default('#5E0513'),

  warning5: z.string().default('#FFF8F0'),
  warning10: z.string().default('#FFEFD6'),
  warning25: z.string().default('#FFD9A3'),
  warning40: z.string().default('#F2B555'),
  warning50: z.string().default('#D98916'),
  warning75: z.string().default('#B56D00'),
  warning100: z.string().default('#995C00'),
  warning125: z.string().default('#B56D00'),
  warning150: z.string().default('#6D4100'),
  warning175: z.string().default('#693E00'),
  warning200: z.string().default('#452900'),

  success5: z.string().default('#F2FAF7'),
  success10: z.string().default('#DFF2E9'),
  success25: z.string().default('#AFE0C9'),
  success40: z.string().default('#73C9A1'),
  success50: z.string().default('#34A872'),
  success75: z.string().default('#1C8B57'),
  success100: z.string().default('#007A41'),
  success125: z.string().default('#006F3B'),
  success150: z.string().default('#00572E'),
  success175: z.string().default('#004324'),
  success200: z.string().default('#00371D'),

  dataviz01: z.string().default('#1676F5'),
  dataviz02: z.string().default('#F54992'),
  dataviz03: z.string().default('#7D41FC'),
  dataviz04: z.string().default('#08CE9B'),
  dataviz05: z.string().default('#EA9D3B'),
  dataviz06: z.string().default('#2430B2'),
  dataviz07: z.string().default('#09B5D4'),
  dataviz08: z.string().default('#8BDD13'),
  dataviz09: z.string().default('#FF5A4D'),
  dataviz10: z.string().default('#952C58'),
})

export const ThemeSchema = z.object({
  font: ThemeFont.default({}),
  colors: ThemeColors.default({}),
})
