import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'
import { createAccount, fetchAccountByType } from './upsertAccount'
import { createSleeve, fetchSleeveByType } from './upsertSleeve'
import { ContributionSchedule } from 'types/enums'

interface Options {
  riskLevel: number
  recurringContribution?: {
    contribution: number
    schedule: ContributionSchedule
  }
  externalAccountId?: string
}

export default function upsertSIP(
  userId: string,
  accountType: Atomic.AccountType,
  options: Options,
  sleeveType: Atomic.SleeveType = 'CUSTOM_INDEXING',
  enforceCustomPortfolioSleeve: boolean
) {
  return async (fetch: Function) => {
    // We should not create SIP when recurring contribution has not been added to onboarding state
    if (!options.recurringContribution) {
      return null
    }

    const body = {
      monetary_value: {
        amount: options.recurringContribution.contribution,
        currency: 'USD',
      },
      schedule: [options.recurringContribution.schedule],
      ...(options.externalAccountId && {
        external_account_id: options.externalAccountId,
      }),
    }

    let account = await fetchAccountByType(userId, accountType)(fetch)
    if (!account) {
      account = await createAccount({
        userId,
        type: accountType,
        riskLevel: options.riskLevel,
      })(fetch)
    }

    let sleeve = await fetchSleeveByType(
      account.account_id,
      sleeveType,
      enforceCustomPortfolioSleeve
    )(fetch)

    if (!sleeve) {
      sleeve = await createSleeve({
        accountId: account.account_id,
        riskLevel: options.riskLevel,
        type: sleeveType,
      })(fetch)
    }

    const sip = await fetchSIP(userId, sleeve.sleeve_id)(fetch)
    if (!sip) {
      return createSIP(sleeve.sleeve_id, body)(fetch)
    }

    if (
      isEqual(
        pick(sip.parameters, [
          'monetary_value',
          'schedule',
          'external_account_id',
        ]),
        body
      )
    ) {
      return
    }

    return [
      deleteSIP(sip.systematic_plan_id),
      createSIP(sleeve.sleeve_id, body),
    ].reduce((result, fn) => result.then(() => fn(fetch)), Promise.resolve())
  }
}

export function fetchSIP(userId: string, sleeveId: string) {
  return async (fetch: Function) => {
    return fetch(
      `/systematic-plans?user_id=${userId}&sleeve_id=${sleeveId}`
    ).then((res: any) => {
      return res?.elements?.[0]
    })
  }
}

function createSIP(sleeveId: string, body: any) {
  return async (fetch: Function) => {
    return fetch('/systematic-plans', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        sleeve_id: sleeveId,
        ...body,
      }),
    })
  }
}

export function deleteSIP(id: string) {
  return async (fetch: Function) => {
    return fetch('/systematic-plans/' + id, {
      method: 'DELETE',
    })
  }
}
