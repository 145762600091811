import classNames from 'classnames'
import { forwardRef, useContext } from 'react'
import { useIntl } from 'react-intl'
import { OnboardingCompleteInProgressContext } from 'shared/useOnboardingNavigation'
import { ButtonBase, ButtonProps } from 'components/core/ButtonBase'

interface SubmitButtonProps extends ButtonProps {
  width?: string
}

const SubmitButton = forwardRef<HTMLButtonElement, SubmitButtonProps>(
  (props, ref) => {
    const savingInProgress = useContext(OnboardingCompleteInProgressContext)
    const {
      width = 'Button--mobile-full',
      disabled,
      className,
      loading,
      ...restProps
    } = props

    const SubmitButtonClassName = classNames('Button', [
      'Button--primary Button--lg Button--submit',
      (loading || savingInProgress) && 'Button--loading',
      width,
    ])

    return (
      <ButtonBase
        type="submit"
        className={SubmitButtonClassName}
        disabled={disabled || savingInProgress || loading}
        loading={savingInProgress || loading}
        data-e2e="primary-btn"
        {...restProps}
        ref={ref}
      />
    )
  }
)
SubmitButton.displayName = 'Button.Submit'

const ButtonContinue = forwardRef<
  HTMLButtonElement,
  Omit<SubmitButtonProps, 'children'>
>((props, ref) => {
  const intl = useIntl()
  return (
    <SubmitButton {...props} ref={ref}>
      {intl.formatMessage({ defaultMessage: 'Continue' })}
    </SubmitButton>
  )
})
ButtonContinue.displayName = 'Button.Continue'

const ButtonSave = forwardRef<
  HTMLButtonElement,
  Omit<SubmitButtonProps, 'children'>
>((props, ref) => {
  const intl = useIntl()
  return (
    <SubmitButton {...props} ref={ref}>
      {intl.formatMessage({ defaultMessage: 'Save' })}
    </SubmitButton>
  )
})
ButtonContinue.displayName = 'Button.Save'

const TertiaryButton = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const { loading, ...restProps } = props
    const className = classNames(
      'Button Button--tertiary Button--lg Button--mobile-full',
      {
        'Button--loading': loading,
      }
    )
    return (
      <ButtonBase
        {...restProps}
        loading={loading}
        className={className}
        ref={ref}
      >
        {props.children}
      </ButtonBase>
    )
  }
)

ButtonSave.displayName = 'Button.Save'

const Button = Object.assign(
  forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
    const { className, ...restProps } = props
    return <ButtonBase {...restProps} ref={ref} />
  }),
  {
    Base: ButtonBase,
    Submit: SubmitButton,
    Tertiary: TertiaryButton,
    Continue: ButtonContinue,
    Save: ButtonSave,
  }
)

Button.displayName = 'Button'

export default Button
