import { ReactNode } from 'react'
import BottomSheet, { BottomSheetHeader } from 'components/BottomSheet'
import { Info } from '@phosphor-icons/react'

interface InfoDrawerProps {
  isOpen: boolean
  icon?: ReactNode
  header: string | ReactNode
  body: string | ReactNode
  onDismiss?: () => void
}

export default function InfoDrawer(props: InfoDrawerProps) {
  return (
    <BottomSheet open={props.isOpen} onDismiss={props.onDismiss}>
      <BottomSheetHeader
        icon={props.icon ?? <Info className="mr-2 size-5" />}
        title={props.header}
      />
      <div className="info-drawer text-neutral100 text-b2">{props.body}</div>
    </BottomSheet>
  )
}
