import { HTMLMotionProps, motion } from 'framer-motion'
import {
  ReactNode,
  isValidElement,
  cloneElement,
  FC,
  forwardRef,
  RefAttributes,
} from 'react'
import classNames from 'classnames'
import useFeature from 'shared/useFeature'

export type ActionBarProps = HTMLMotionProps<'div'> &
  RefAttributes<HTMLDivElement>

export interface ActionBarStickyProps extends ActionBarProps {
  stickyOn: boolean
}

interface WithClassName {
  className?: string
}

// Base
const Base = forwardRef<HTMLDivElement, ActionBarProps>((props, ref) => {
  const suppressAnimation = useFeature('dev-suppress-page-animation')
  return (
    <motion.div
      ref={ref}
      initial={suppressAnimation ? undefined : { opacity: 0 }}
      exit={suppressAnimation ? undefined : { opacity: 0 }}
      animate={suppressAnimation ? undefined : { opacity: 1 }}
      {...props}
      className={classNames(props.className, '-mx-margin z-20 px-2')}
    >
      {props.children}
    </motion.div>
  )
})

const StickyBase = forwardRef<HTMLDivElement, ActionBarStickyProps>(
  (props, ref) => {
    const suppressAnimation = useFeature('dev-suppress-page-animation')
    const { stickyOn, ...restProps } = props
    return (
      <div ref={ref}>
        {/* Sticky OFF */}
        <Base
          initial={
            suppressAnimation
              ? undefined
              : {
                  opacity: 1,
                }
          }
          animate={
            suppressAnimation
              ? undefined
              : {
                  opacity: stickyOn ? 0 : 1,
                  transition: { duration: 0.1 },
                }
          }
          style={{ zIndex: 1 }}
          {...restProps}
          className={classNames(
            props.className,
            'tbm:px-9 desktop:px-[114px] desktop:py-2'
          )}
        />

        {/* Sticky ON */}
        <BorderB>
          <Absolute>
            <BoxTransclucent>
              <Base
                initial={
                  suppressAnimation
                    ? undefined
                    : {
                        opacity: 0,
                      }
                }
                animate={
                  suppressAnimation
                    ? undefined
                    : {
                        opacity: stickyOn ? 1 : 0,
                        transition: { duration: 0.15 },
                      }
                }
                style={{
                  zIndex: 20,
                  backgroundColor: 'rgba(var(--color-neutral0), 0.84)',
                }}
                {...restProps}
                className={classNames(
                  props.className,
                  'pt-safe left-0 top-0 mbs:mx-0',
                  'tbm:px-9 desktop:px-[114px] desktop:py-2'
                )}
              />
            </BoxTransclucent>
          </Absolute>
        </BorderB>
      </div>
    )
  }
)

// Modifiers
const Absolute: FC<WithClassName> = (props) => {
  return addClassName(['absolute w-full', props.className], {
    node: props.children,
  })
}

const Desktop: FC<WithClassName> = (props) => {
  const desktopClassName = [
    'tbm:bg-transparent tbm:absolute tbm:w-full',
    'tbm:top-0 tbm:left-0',
    'tbm:mx-0 tbm:px-9 desktop:px-[114px] desktop:py-2',
  ]

  return addClassName([...desktopClassName, props.className], {
    node: props.children,
  })
}

const BoxWhite: FC<WithClassName> = (props) => {
  return addClassName(['bg-neutral0 text-neutral200', props.className], {
    node: props.children,
  })
}

const BoxTransparent: FC<WithClassName> = (props) => {
  return addClassName(['bg-transparent text-neutral0', props.className], {
    node: props.children,
  })
}

const BoxTransclucent: FC<WithClassName> = (props) => {
  return addClassName(['backdrop-filter backdrop-blur', props.className], {
    node: props.children,
  })
}

const BorderB: FC<WithClassName> = (props) => {
  return addClassName(['border-neutral10 border-b', props.className], {
    node: props.children,
  })
}

// Variants
const ActionBar: any = forwardRef<HTMLDivElement, ActionBarProps>(
  (props, ref) => {
    return (
      <Desktop>
        <Base ref={ref} {...props} />
      </Desktop>
    )
  }
)

const ActionBarHover = forwardRef<HTMLDivElement, ActionBarProps>(
  (props, ref) => {
    return (
      <BoxTransparent>
        <Desktop>
          <Absolute>
            <Base
              ref={ref}
              {...props}
              className={classNames(props.className, 'z-20')}
            />
          </Absolute>
        </Desktop>
      </BoxTransparent>
    )
  }
)

const ActionBarSticky = forwardRef<HTMLDivElement, ActionBarStickyProps>(
  (props, ref) => {
    return (
      <BoxWhite>
        <StickyBase ref={ref} {...props} />
      </BoxWhite>
    )
  }
)

ActionBarSticky.displayName = 'ActionBarSticky'

interface ActionBarFC extends FC<ActionBarProps> {
  Hover: FC<ActionBarProps>
  Sticky: FC<ActionBarStickyProps>
  Base: FC<ActionBarProps>
}

ActionBar.Hover = ActionBarHover
ActionBar.Sticky = ActionBarSticky
ActionBar.Base = Base

export default ActionBar as ActionBarFC

function createComponent(
  node: ReactNode,
  callback: (childClassName: string) => string
) {
  return isValidElement(node)
    ? cloneElement(node, {
        // @ts-ignore
        className: callback(node.props.className),
      })
    : null
}

function addClassName(
  classNamesToAdd: string | Array<string | undefined>,
  options: { node: ReactNode }
) {
  return createComponent(options.node, (childClassName) =>
    classNames(childClassName, classNamesToAdd)
  )
}
