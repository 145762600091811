import { fetchAccountByType } from './upsertAccount'
import { fetchSleeveByType } from './upsertSleeve'
import { deleteSIP, fetchSIP } from './upsertSIP'

export default function deleteIfAvailableSIP(
  userId: string,
  accountType: Atomic.AccountType,
  sleeveType: Atomic.SleeveType = 'CUSTOM_INDEXING',
  enforceCustomPortfolioSleeve: boolean
) {
  return async (fetch: Function) => {
    let account = await fetchAccountByType(userId, accountType)(fetch)
    if (!account) {
      return null
    }

    let sleeve = await fetchSleeveByType(
      account.account_id,
      sleeveType,
      enforceCustomPortfolioSleeve
    )(fetch)

    if (!sleeve) {
      return null
    }

    const sip = await fetchSIP(userId, sleeve.sleeve_id)(fetch)
    if (!sip) {
      return null
    }

    return await deleteSIP(sip.systematic_plan_id)(fetch)
  }
}
