import { useCallback } from 'react'
import useSession from 'shared/useSession'
import useSettings from 'shared/useSettings'
import useFetch from 'shared/useFetch'
import localStorageHelper from 'shared/localStorageHelper'
import useOnboardingState from './useOnboardingState'
import updateUser from './updateUser'
import upsertSIP from './upsertSIP'
import upsertSleeve from './upsertSleeve'
import { DEFAULT_RECOMMENDED_RISK_LEVEL } from 'views/retail/pages/RecordRiskLevelPage'

export default function useSaveOnboardingState() {
  const session = useSession()
  const settings = useSettings()
  const fetch = useFetch()
  const [state] = useOnboardingState()
  const enforceCustomPortfolioSleeve =
    !settings.portfolio_types.includes('HIGH_YIELD')

  return useCallback(async () => {
    if (
      session.sessionType === 'onboarding' &&
      !session.entity.isOnboardingFinished()
    ) {
      await updateUser(session.userId, state)(fetch)
    }

    const [account] = await upsertSleeve(
      session.userId,
      state.riskLevel ?? DEFAULT_RECOMMENDED_RISK_LEVEL,
      state.accountType,
      state.sleeveType,
      enforceCustomPortfolioSleeve
    )(fetch)

    await upsertSIP(
      session.userId,
      state.accountType,
      {
        recurringContribution: state.recurringContribution,
        riskLevel: state.riskLevel ?? DEFAULT_RECOMMENDED_RISK_LEVEL,
        externalAccountId: state.externalAccountId,
      },
      state.sleeveType,
      enforceCustomPortfolioSleeve
    )(fetch)

    // temporary save Persona progress to localStorage, it is needed to open Dashboard
    // (identity_verification still equals to IDENTITY_VERIFICATION_NOT_STARTED)
    if (state.taxProfile?.personaInquiryStarted) {
      localStorageHelper.save('personaInquiryStarted', session.userId)
    }

    return { account }
  }, [session, state, fetch, enforceCustomPortfolioSleeve])
}
