import { ReactNode, useEffect, useLayoutEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { motion, AnimatePresence } from 'framer-motion'

interface DrawerProps {
  children: ReactNode
  isOpen: boolean
  onDismiss?: () => void
  offsetTop?: number
  elRef?: Element | null
}

const backdropVariants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
}

const modalVariants = {
  visible: {
    y: 0,
    transition: { duration: 0.4, ease: [0.36, 0.66, 0.04, 1] },
  },
  hidden: {
    y: '100%',
    transition: { duration: 0.3, ease: [0.36, 0.66, 0.04, 1] },
  },
}

export default function Drawer(props: DrawerProps) {
  return (
    <DrawerMain {...props}>
      <DrawerContainer onDismiss={props.onDismiss}>
        <DrawerContent offsetTop={props.offsetTop || 48}>
          {props.children}
        </DrawerContent>
      </DrawerContainer>
    </DrawerMain>
  )
}

export function DrawerMain({
  isOpen,
  onDismiss,
  children,
  elRef,
}: DrawerProps) {
  const ref = useRef<Element | null>(null)
  // eslint-disable-next-line
  const [_, forceRefresh] = useState(Date.now())

  useLayoutEffect(() => {
    ref.current = elRef ? elRef : document.querySelector('#page-drawer')
    forceRefresh(Date.now())
  }, [elRef])

  useEffect(() => {
    function handleKeyDown(event: any) {
      if (isOpen && event.code === 'Escape') {
        onDismiss?.()
      }
    }

    window.addEventListener('keydown', handleKeyDown, false)
    return () => {
      window.removeEventListener('keydown', handleKeyDown, false)
    }
  }, [isOpen, onDismiss])

  if (!ref.current) {
    return null
  }

  return ReactDOM.createPortal(
    <AnimatePresence>{isOpen && children}</AnimatePresence>,
    ref.current as Element
  )
}

export function DrawerContainer({ onDismiss, children }: any) {
  useEffect(() => {
    const originalValue = document.body.style.overflow
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = originalValue
    }
  }, [])

  return (
    <div id="drawer-container" className="absolute inset-0 z-[2000]">
      <motion.div
        id="drawer-backdrop"
        className="absolute inset-0"
        initial="hidden"
        animate="visible"
        exit="hidden"
        variants={backdropVariants}
        onClick={onDismiss}
      />
      {children}
    </div>
  )
}

export function DrawerContent(props: any) {
  return (
    <motion.div
      id="drawer-content"
      className="absolute bottom-0 max-h-full w-full overflow-y-auto"
      style={{ paddingBottom: props.offsetTop }}
      initial="hidden"
      exit="hidden"
      animate="visible"
      variants={{
        hidden: {
          y: '100%',
          transition: { duration: 0.3, ease: [0.36, 0.66, 0.04, 1] },
        },
        visible: {
          y: props.offsetTop,
          transition: { duration: 0.4, ease: [0.36, 0.66, 0.04, 1] },
        },
      }}
    >
      <motion.div className="sticky top-0 z-20 flex h-6 justify-center rounded-t-xl bg-neutral0">
        <div
          className="mt-2 w-9 rounded-full bg-neutral25"
          style={{ height: 5 }}
        />
      </motion.div>
      <div className="px-margin pb-safe bg-neutral0">{props.children}</div>
    </motion.div>
  )
}

export function DrawerModal(props: any) {
  return (
    <motion.div
      id="drawer-modal"
      className="absolute inset-0 flex items-center justify-center"
      initial="hidden"
      exit="hidden"
      animate="visible"
      variants={modalVariants}
    >
      <div className="px-margin rounded-lg bg-neutral0">{props.children}</div>
    </motion.div>
  )
}
