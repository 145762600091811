import { OnboardingState } from './onboarding/useOnboardingState'

export type Step = {
  id: OnboardingPage
  path: string
  enabled_when?: (state: OnboardingState, settings: Settings) => boolean
  disabled_when?: (state: OnboardingState, settings: Settings) => boolean
}

export function isEnabled(
  step: Step,
  state: OnboardingState,
  settings: Settings
) {
  if (step.disabled_when && step.enabled_when) {
    throw new TypeError(
      'Invalid step: provided both disabled_when and enabled_when'
    )
  }

  if (step.disabled_when) {
    return !step.disabled_when(state, settings)
  } else if (step.enabled_when) {
    return step.enabled_when(state, settings)
  } else {
    return true
  }
}
