import { DEFAULT_RECOMMENDED_RISK_LEVEL } from 'views/retail/pages/RecordRiskLevelPage'

export default function upsertAccount(
  userId: string,
  type: Atomic.AccountType = 'INDIVIDUAL',
  riskLevel: number = DEFAULT_RECOMMENDED_RISK_LEVEL
) {
  return async (fetch: Function) => {
    const account = await fetchAccountByType(userId, type)(fetch)
    if (!account) {
      return createAccount({ userId, riskLevel, type })(fetch)
    }
    return account
  }
}

export function fetchAccountByType(userId: string, type: Atomic.AccountType) {
  return async (fetch: Function) => {
    const rsp = await fetch('/accounts?user_id=' + encodeURIComponent(userId))
    const elements = rsp.elements ?? []
    return elements.find((account: any) => account.account_type === type)
  }
}

export function fetchAccountsByUserId(userId: string) {
  return async (fetch: Function): Promise<Atomic.Account[]> => {
    const rsp = await fetch('/accounts?user_id=' + encodeURIComponent(userId))
    return rsp.elements ?? []
  }
}

export function createAccount(data: {
  userId: string
  riskLevel: number
  type: Atomic.AccountType
}) {
  return (fetch: Function) => {
    const type = data.type ?? 'INDIVIDUAL'
    const riskLevel = data.riskLevel ?? DEFAULT_RECOMMENDED_RISK_LEVEL
    return fetch('/accounts', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_id: data.userId,
        account_type: type,
        preferences: {
          risk_score: riskLevel,
        },
        ...(type === 'IRA_ROTH' && {
          type_parameters: { roth_ira: { type: 'individual' } },
        }),
      }),
    })
  }
}
