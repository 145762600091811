import { useState, useEffect } from 'react'

interface WindowSize {
  width?: number
  height?: number
  paddingTopSafe: number
  paddingBottomSafe: number
}

export default function useWindowSize(): WindowSize {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: undefined,
    height: undefined,
    paddingTopSafe: 0,
    paddingBottomSafe: 0,
  })

  useEffect(() => {
    function handleResize() {
      const bbox = document.documentElement.getBoundingClientRect()
      setWindowSize({
        width: bbox.width,
        height: bbox.height,
        paddingTopSafe: getSafeAreaPadding('--sat'),
        paddingBottomSafe: getSafeAreaPadding('--sab'),
      })
    }

    window.addEventListener('resize', handleResize)
    window.addEventListener('orientationchange', handleResize)
    handleResize()

    // need to re-call handleResize() after some time
    // solve problem for WebView - window.innerHeight === 0
    let i = 0
    const interval = setInterval(() => {
      setWindowSize((state) => {
        if ((!state.height || state.height < 200) && i < 10) {
          ++i

          const bbox = document.documentElement.getBoundingClientRect()
          return {
            width: bbox.width,
            height: bbox.height,
            paddingTopSafe: getSafeAreaPadding('--sat'),
            paddingBottomSafe: getSafeAreaPadding('--sab'),
          }
        } else {
          clearInterval(interval)
          return state
        }
      })
    }, 50)

    return () => {
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('orientationchange', handleResize)
      clearInterval(interval)
    }
  }, [])

  return windowSize
}

function getSafeAreaPadding(variable: '--sat' | '--sar' | '--sab' | '--sal') {
  const paddingTopString = getComputedStyle(
    document.documentElement
  ).getPropertyValue(variable)
  return parseInt(paddingTopString)
}
