import { ReactNode, useCallback, useState } from 'react'
import { useContext, useMemo, createContext } from 'react'
import usePostMessageChannel from './usePostMessageChannel'
import { DrawerContainer, DrawerModal, DrawerMain } from '../components/Drawer'
import { makePayload, usePageAnalyticsState } from './usePageAnalytics'
import useSession from './useSession'
import { useIntl } from 'react-intl'
import { Warning } from '@phosphor-icons/react'

interface ECProps {
  children: ReactNode
  defaultVisible?: boolean
}

const noop = () => {}
export const ExitConfirmCtx = createContext(noop)
const ExitConfirmInternalCtx = createContext({
  isConfirmVisible: false,
  onDismiss: noop,
  onConfirm: noop,
})

export function ExitConfirmProvider(props: ECProps) {
  const session = useSession()
  const { exit, send } = usePostMessageChannel()
  const analyticsState = usePageAnalyticsState()
  const [isConfirmVisible, setConfirmVisible] = useState(
    props.defaultVisible ?? false
  )

  const onConfirm = useCallback(() => {
    if (session.sessionType === 'onboarding') {
      send({
        type: 'ANALYTICS',
        subtype: 'ONBOARDING_DROPPED',
        payload: makePayload(analyticsState, {
          dropped_off: true,
        }),
      })
    }
    exit()
  }, [session, exit, send, analyticsState])

  const onExit = useCallback(() => {
    setConfirmVisible(true)
  }, [])

  const ctx = useMemo(
    () => ({
      isConfirmVisible,
      onConfirm,
      onDismiss: () => setConfirmVisible(false),
    }),
    [isConfirmVisible, onConfirm]
  )

  return (
    <ExitConfirmInternalCtx.Provider value={ctx}>
      <ExitConfirmCtx.Provider value={onExit}>
        {props.children}
      </ExitConfirmCtx.Provider>
    </ExitConfirmInternalCtx.Provider>
  )
}

export default function useExitConfirm() {
  return useContext(ExitConfirmCtx)
}

export function ExitConfirmDrawer() {
  const intl = useIntl()
  const ctx = useContext(ExitConfirmInternalCtx)

  return (
    <DrawerMain isOpen={ctx.isConfirmVisible} onDismiss={ctx.onDismiss}>
      <DrawerContainer onDismiss={ctx.onDismiss}>
        <DrawerModal>
          <div className="flex flex-col items-center pb-6 pt-9">
            <div className="flex h-16 w-16 items-center justify-center rounded-full bg-secondary100 bg-opacity-8">
              <Warning
                size="28"
                className="text-secondary100"
                style={{ transform: 'translateY(-1px)' }}
              />
            </div>

            <p className="mt-6 w-[269px] text-center text-neutral200 text-s2">
              {intl.formatMessage({
                defaultMessage: 'Do you really want to quit?',
              })}
            </p>

            <div className="mt-9 grid w-full grid-cols-2 gap-3">
              <button
                type="button"
                className="h-12 rounded bg-primary100 text-neutral0 text-s3 active:bg-opacity-70"
                onClick={ctx.onDismiss}
              >
                {intl.formatMessage({ defaultMessage: 'Cancel' })}
              </button>
              <button
                type="button"
                className="h-12 rounded text-primary100 ring-1 ring-neutral25 text-s3 active:bg-neutral10"
                onClick={ctx.onConfirm}
              >
                {intl.formatMessage({ defaultMessage: 'Yes, quit' })}
              </button>
            </div>
          </div>
        </DrawerModal>
      </DrawerContainer>
    </DrawerMain>
  )
}
