import { X } from '@phosphor-icons/react'
import classNames from 'classnames'
import { forwardRef, Ref, ReactNode } from 'react'
import useViewport from 'shared/useViewport'
import { Drawer } from 'vaul'

export type BottomSheetProps = {
  open?: boolean
  onOpenChange?: (open: boolean) => void
  onDismiss?: () => void
  className?: string
  children: ReactNode
  trigger?: ReactNode
  snapPoints?: (number | string)[]
}

export const BottomSheet = forwardRef(
  (props: BottomSheetProps, ref: Ref<HTMLDivElement>) => {
    const isTablet = useViewport().matchSize('tbm')
    function handleOpenChange(open: boolean) {
      if (!open) {
        props.onDismiss?.()
      }
    }
    return (
      <Drawer.Root
        open={props.open}
        onOpenChange={props.onOpenChange ?? handleOpenChange}
        snapPoints={props.snapPoints}
        direction={isTablet ? 'right' : 'bottom'}
      >
        {props.trigger && (
          <Drawer.Trigger asChild>{props.trigger}</Drawer.Trigger>
        )}
        <Drawer.Portal>
          <Drawer.Overlay className="BottomSheetOverlay" />
          <Drawer.Content
            className={classNames('BottomSheet', props.className)}
            ref={ref}
          >
            <div className="BottomSheetContainer">
              <BottomSheetHandle />
              {props.children}
            </div>
          </Drawer.Content>
        </Drawer.Portal>
      </Drawer.Root>
    )
  }
)
BottomSheet.displayName = 'BottomSheet'

interface BottomSheetHeaderProps {
  title?: string | ReactNode
  icon?: ReactNode
  onClick?: () => void
}

export const BottomSheetHeader = forwardRef<
  HTMLDivElement,
  BottomSheetHeaderProps
>((props, ref) => {
  return (
    <div className="BottomSheetHeader">
      <div ref={ref} className="BottomSheetHeader-container">
        <div className="flex items-center" onClick={props.onClick}>
          {props.icon}
          {props.title && (
            <div className="BottomSheetHeader-title">{props.title}</div>
          )}
        </div>
        <Drawer.Close className="outline-none" data-e2e="close">
          <X className="size-6" />
        </Drawer.Close>
      </div>
    </div>
  )
})
BottomSheetHeader.displayName = 'BottomSheetHeader'

export const BottomSheetHandle = forwardRef<HTMLDivElement>(() => {
  return (
    <div className="BottomSheetHandle">
      <div className="BottomSheetHandle-line" />
    </div>
  )
})
BottomSheetHandle.displayName = 'BottomSheetHandle'

export default BottomSheet
