import { ComponentProps } from 'react'
import classNames from 'classnames'
import useFeature from 'shared/useFeature'
import { CaretLeft } from '@phosphor-icons/react'

export default function BackBtn(props: ComponentProps<'button'>) {
  const hideBackButton =
    useFeature('hide-desktop-back-button') || !props.onClick

  return (
    <button
      type="button"
      data-e2e="back-btn"
      {...props}
      className={classNames(
        { invisible: hideBackButton },
        'z-10 flex h-12 w-12 items-center justify-center'
      )}
    >
      <CaretLeft className="size-6" />
    </button>
  )
}
