import isEmpty from 'lodash/isEmpty'
import merge from 'lodash/merge'
import isNil from 'lodash/isNil'
import { OnboardingState } from './useOnboardingState'
import { toStringDate } from 'shared/custom-date'
import { formatSSN } from '../taxIdHelpers'

export default function updateUser(
  userId: string,
  savedState: Partial<OnboardingState>
) {
  const state = merge({}, savedState)
  const financial_profile = {
    ...(state.investmentPreference && {
      investment_priority: state.investmentPreference,
    }),
    ...(state.downturnReaction && {
      downturn_reaction: state.downturnReaction,
    }),
    ...(state.timeHorizon && {
      investment_horizon: state.timeHorizon,
    }),
    ...(state.annualGrossIncome && {
      annual_gross_income: {
        amount: Number(state.annualGrossIncome),
        currency: 'USD',
      },
    }),
  }

  const identity = {
    ...(state.personalInfo?.firstName && {
      first_name: state.personalInfo?.firstName,
    }),
    ...(state.personalInfo?.lastName && {
      last_name: state.personalInfo?.lastName,
    }),
    ...(state.personalInfo?.birthday && {
      date_of_birth: toStringDate(state.personalInfo?.birthday),
    }),
    ...(state.address && {
      address: {
        ...state.address,
        ...(isNil(state.address.country_code) && {
          country_code:
            state.citizenship && state.citizenship.length > 0
              ? state.citizenship[0]
              : 'USA',
        }),
      },
    }),
    ...(state.contactInfo?.email && {
      email: state.contactInfo?.email,
    }),
    ...(state.contactInfo?.phone && {
      phone_number: state.contactInfo?.phone.replace('+', ''),
    }),
    ...(state.citizenship &&
      state.citizenship.length > 0 && {
        country_of_citizenship: state.citizenship,
        residence_status:
          state.usStatus ??
          (state.citizenship.includes('USA') ? 'CITIZEN' : 'NONE'),
      }),
  }

  let tax_profile
  if (!state.taxProfile?.personaInquiryStarted) {
    const country_of_tax_residence =
      state.taxProfile?.countryOfTaxResidence || 'USA'
    const defaultTaxIdType =
      country_of_tax_residence === 'USA' ? 'USA_SSN' : 'OTHER_TAX_ID'
    const tax_id_type = state.taxProfile?.taxIdType || defaultTaxIdType
    const tax_id =
      tax_id_type === 'USA_SSN'
        ? state.personalInfo?.ssn !== '*********' &&
          formatSSN(state.personalInfo?.ssn)
        : state.taxProfile?.taxId
    tax_profile = {
      ...(tax_id && {
        country_of_tax_residence,
        tax_id,
        tax_id_type,
      }),
    }
  }

  const employment = {
    ...(state.employment && {
      employment: {
        status: state.employment,
        ...(state.employer && {
          manual_employer_information: {
            employer_name: state.employer.name,
            employer_address: state.employer.address,
          },
        }),
      },
      ...(['EMPLOYED', 'SELF_EMPLOYED'].includes(state.employment) &&
        state.employer && {
          occupation: {
            title: state.employer.role,
            industry: state.employer.industry,
          },
        }),
    }),
  }

  const affiliations = {
    ...(state.affiliations && {
      is_finra_affiliated: state.affiliations?.includes('FINRA'),
      is_politically_exposed: state.affiliations?.includes(
        'POLITICALLY_EXPOSED'
      ),
      is_family_politically_exposed: state.affiliations?.includes(
        'POLITICALLY_EXPOSED'
      ),
    }),
  }

  const trusted_contact = {
    ...(state.trustedContact && {
      first_name: state.trustedContact.firstName,
      last_name: state.trustedContact.lastName,
      email: state.trustedContact.email,
    }),
  }

  const occupation = {
    ...(state.occupation && {
      title: state.occupation.title,
      industry: state.occupation.industry,
    }),
  }

  const body = {
    ...(!isEmpty(financial_profile) && { financial_profile }),
    ...(!isEmpty(identity) && { identity }),
    ...(!isEmpty(tax_profile) && { tax_profile }),
    ...(!isEmpty(affiliations) && { affiliations }),
    ...(!isEmpty(trusted_contact) && { trusted_contact }),
    ...(!isEmpty(employment) && { ...employment }),
    ...(!isEmpty(occupation) && { occupation }),
    ...(state.investmentPrinciples && {
      investment_principles: state.investmentPrinciples,
    }),
    ...(state.restrictions && {
      restrictions: state.restrictions,
    }),
  }

  return (fetch: Function) => {
    if (isEmpty(body)) {
      return null
    }

    return fetch('/users/' + userId, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }).catch(() => {
      throw new Error('Failed to update user ' + userId)
    })
  }
}
