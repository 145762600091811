import { HTMLProps, useCallback, useState } from 'react'
import { motion } from 'framer-motion'
import classNames from 'classnames'
import { useIntl } from 'react-intl'
import { MagnifyingGlass, XCircle } from '@phosphor-icons/react'

interface SearchInputProps extends HTMLProps<HTMLInputElement> {
  noCancelBtn?: boolean
  noBg?: boolean
  clearBtn?: boolean
  isFocused?: (focused: boolean) => void
  focused?: boolean
}

export default function SearchInput(props: SearchInputProps) {
  const intl = useIntl()
  const {
    value,
    noCancelBtn,
    noBg = false,
    clearBtn,
    isFocused,
    focused: forceFocused,
    className,
    ...domProps
  } = props
  const [innerFocused, setFocused] = useState<boolean>(false)
  const focused = forceFocused ?? innerFocused

  const clear = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      if (props.onChange) {
        props.onChange({
          target: {
            value: '',
          },
        } as any)
      }
    },
    [props]
  )

  const handleFocus = () => {
    setFocused(true)
    isFocused?.(true)
  }

  const handleBlur = () => {
    setFocused(false)
    isFocused?.(false)
  }

  return (
    <div className={classNames('SearchInput')}>
      <input
        className={classNames('SearchInput-input', className, {
          'SearchInput-input--with-cancel': !noCancelBtn,
          'SearchInput-input--with-bg': !noBg,
        })}
        placeholder={intl.formatMessage({ defaultMessage: 'Search' })}
        value={value}
        onChange={props.onChange}
        autoComplete="off"
        onFocus={handleFocus}
        onBlur={handleBlur}
        {...domProps}
      />

      <MagnifyingGlass className="h-5 w-5" />

      {noCancelBtn || (
        <motion.button
          className="SearchInput-cancel-button"
          type="button"
          onMouseDown={handleBlur}
          animate={{
            x: focused ? 0 : 100,
            opacity: focused ? 1 : 0,
          }}
          transition={{
            duration: 0.2,
          }}
        >
          {intl.formatMessage({ defaultMessage: 'Cancel' })}
        </motion.button>
      )}

      {clearBtn && value && (
        <button
          className="SearchInput-clear-button"
          type="button"
          onMouseDown={clear}
        >
          <XCircle className="h-5 w-5" />
        </button>
      )}
    </div>
  )
}
