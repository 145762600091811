import { z } from 'zod'
import { ThemeSchema } from './theme.schema'

const Mode = z.enum(['api-sandbox', 'sandbox', 'api-live', 'production'])
const Cluster = z.enum(['prod', 'qa', 'staging'])
const CustomCssOption = z.enum(['production', 'staging', 'local'])
const View = z.enum([
  'retail',
  'card',
  'identity',
  'investor-documents',
  'agreement',
  'corporate',
  'kyc-retry',
  'esignature',
])

const ExcludedOnboardingPage = z.enum([
  'page-get-started',
  'page-how-it-works',
  'page-investment-preference',
  'page-time-horizon',
  'page-large-drop',
  'page-gross-income',
  'page-included-investing',
  'page-excluded-investing',
  'page-risk-level',
  'page-investment-plan',
  'page-initial-contribution',
  'page-verify-identity',
  'page-personal-info',
  'page-tax-id',
  'page-legal-address',
  'page-contact-info',
  'page-us-status',
  'page-emp-status',
  'page-affiliations',
  'page-marital-status',
  'page-trusted-contact',
  'page-connect-account',
  'page-wire-details',
  'page-review',
  'page-sign-agreement',
  'page-onboarding-success',
  'page-citizenship',
  'page-us-tax-status',
  'page-us-residence',
  'page-resume-onboarding',
  'page-kyc-retry',
  'page-beneficiary-list',
  'page-beneficiary-relationship',
  'page-account-type',
  'page-account-description',
  'page-account-setup-success',
])

const SessionAuth = z.object({
  session_token: z.string(),
  entity_type: z.enum(['user', 'corporate']),
  entity_id: z.string(),
  identifier: z.string(),
})

const WireConfig = z
  .object({
    funds_to: z.string(),
    routing_number: z.string(),
    swift_code: z.string(),
    account_address: z.string(),
    account_number: z.string(),
    payment_reference: z.string(),
  })
  .optional()

const Tenant = z.object({
  id: z.string(),
  name: z.string(),
  url: z.string(),
})

const EntityType = z.enum(['USER', 'PARTICIPANT', 'CORPORATE'])
const Entity = z.object({
  id: z.string(),
  type: EntityType,
  name: z.string().optional(),
})
const Entities = z.object({
  active: Entity,
  additional: z.array(Entity),
})

const Settings = z.object({
  initial_investment_steps: z.tuple([z.number(), z.number(), z.number()]),
  contribution_plan_amount: z.number(),
})

const OAuthConfig = z.object({
  redirect_uri: z.string(),
})

export const ConfigSchema = z
  .object({
    token: z.union([z.string(), SessionAuth]),

    // Provide target api to use
    mode: Mode,
    cluster: Cluster,

    // Override mode/cluster
    _override_api: z.string(),

    // Provide URL that would be used to send events
    origin: z.string(),

    shouldCloseAfterOnboarding: z.boolean(),
    excluded_onboarding_pages: z.array(ExcludedOnboardingPage),
    summary_pages: z.array(ExcludedOnboardingPage),
    features: z.array(z.string()),

    custom_css: CustomCssOption,
    theme: z.union([ThemeSchema, z.literal('custom')]),
    text: z.enum(['custom']),
    view: View,
    wire_instructions: WireConfig,
    tenants: z.array(Tenant),
    active_tenant: Tenant,
    settings: Settings,
    entities: Entities,
    oauth: OAuthConfig,
    end_user_ip: z.string(),
    language: z.enum(['en', 'pt']),
    esignature: z.object({
      document: z.enum(['w8ben', 'w8bene']),
    }),
    brokerage_mode: z.boolean(),

    // DEPRECATED
    content: ThemeSchema, // use `theme` instead
    customCss: CustomCssOption, // use `custom_css` instead
  })
  .partial()
  .required({ token: true })
  .passthrough()

export type Config = z.infer<typeof ConfigSchema>
