import { ReactNode } from 'react'
import { FSLayer } from 'components'
import useViewport from 'shared/useViewport'
import classNames from 'classnames'

interface FooterProps {
  className?: string
  noBg?: boolean
  children: ReactNode
}

export function Footer(props: FooterProps) {
  const { noBg = false, children, className = '' } = props
  const viewport = useViewport()

  return (
    <div className="Footer">
      {!viewport.matchSize('tbm') ? (
        <FSLayer>
          <div
            className={classNames(
              'pb-safe pointer-events-auto absolute bottom-0 left-0 z-[1] w-full',
              {
                'bg-neutral0/75 backdrop-blur': !noBg,
              }
            )}
          >
            <div className="footer-content px-margin flex w-full flex-col items-center space-y-3 pb-3 pt-2 tbs:mx-auto tbs:max-w-column tbs:px-0 tbs:pb-6">
              {children}
            </div>
          </div>
        </FSLayer>
      ) : (
        <div
          className={classNames(
            'pb-safe margin-0-auto pointer-events-auto relative bottom-0 left-0 z-[1] m-auto w-auto max-w-[1025px]',
            className,
            {
              'bg-neutral0/75 backdrop-blur': !noBg,
            }
          )}
        >
          <div className="footer-content flex w-auto min-w-column flex-col items-center space-y-3 tbm:py-6">
            {children}
          </div>
        </div>
      )}
    </div>
  )
}

export default Footer
