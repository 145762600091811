import { createContext, ReactNode, useContext, useState } from 'react'

export type PAGE_ANIMATION_STATUS = 'none' | 'start' | 'enter' | 'exit'

const PageAnimationStatusContext = createContext<PAGE_ANIMATION_STATUS>('none')

export default function usePageAnimationStatus() {
  return useContext(PageAnimationStatusContext)
}

interface PASPProps {
  children: ReactNode | Function
}

export function PageAnimationStatusProvider({ children }: PASPProps) {
  const [status, setStatus] = useState<PAGE_ANIMATION_STATUS>('none')

  return (
    <PageAnimationStatusContext.Provider value={status}>
      {typeof children === 'function' ? children(setStatus) : children}
    </PageAnimationStatusContext.Provider>
  )
}
