import { validateDate } from '../components/fields/DateField'
import format from 'date-fns/format'

export interface CustomDate {
  day: string
  month: string
  year: string
}

export function isValidDate(value?: CustomDate) {
  const dataStr = `${value?.year}-${value?.month}-${value?.day}`
  if (!value || dataStr.length !== 10) {
    return false
  }

  const parsedValue = new Date(dataStr)
  return parsedValue.toString() !== 'Invalid Date'
}

export function fromStringDate(date?: string): CustomDate | undefined {
  if (
    !date ||
    date.length !== 10 ||
    new Date(date).toString() === 'Invalid Date'
  ) {
    return
  }

  const [year, month, day] = date.split('-')
  return {
    year,
    month,
    day,
  }
}

export function toStringDate(date?: CustomDate): string | undefined {
  const validationError = validateDate(date)
  if (validationError) {
    return
  }

  return `${date?.year}-${date?.month}-${date?.day}`
}

export function fromCustomToCustomUIDate(date?: CustomDate): string {
  const validationError = validateDate(date)
  if (validationError) {
    return ''
  }

  return `${date?.month}/${date?.day}/${date?.year}`
}

export function fromCustomToCustomCorporateDate(date?: CustomDate): string {
  const validationError = validateDate(date)
  if (validationError) {
    return ''
  }

  return new Date(
    `${date?.month}/${date?.day}/${date?.year}`
  ).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })
}

export function documentDateFormat(date?: string): string {
  if (
    !date ||
    date.length !== 10 ||
    new Date(date).toString() === 'Invalid Date'
  ) {
    return ''
  }

  const [year, month, day] = date.split('-')
  return format(new Date(`${year}/${month}/${day}`), 'MMM d, yyyy')
}

export function fromNativeDate(date: Date): CustomDate {
  const [year, month, day] = format(date, 'yyyy-MM-dd').split('-')
  return {
    year,
    month,
    day,
  }
}

export function toNativeDate(date: CustomDate): Date {
  return new Date(`${date.year}/${date.month}/${date.day}`)
}

export function compare(a: CustomDate, b: CustomDate) {
  if (Number(a.year) < Number(b.year)) {
    return -1
  } else if (Number(a.year) > Number(b.year)) {
    return 1
  } else if (Number(a.month) < Number(b.month)) {
    return -1
  } else if (Number(a.month) > Number(b.month)) {
    return 1
  } else if (Number(a.day) < Number(b.month)) {
    return -1
  } else if (Number(a.day) > Number(b.day)) {
    return 1
  } else {
    return 0
  }
}
