import { ComponentProps, forwardRef, ReactNode } from 'react'
import Spinner from 'components/Spinner'

export interface ButtonProps extends ComponentProps<'button'> {
  iconBefore?: ReactNode
  iconAfter?: ReactNode
  loading?: boolean
}

export const ButtonBase = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const { children, loading, onClick, iconBefore, iconAfter, ...restProps } =
      props
    return (
      <button onClick={loading ? undefined : onClick} {...restProps} ref={ref}>
        {loading ? <Spinner classNames="Button-spinner" /> : iconBefore}
        {children}
        {iconAfter}
      </button>
    )
  }
)
ButtonBase.displayName = 'Button.Base'
