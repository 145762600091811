import { useState, useLayoutEffect, useCallback } from 'react'
import usePageAnimationStatus from './usePageAnimationStatus'

interface BBox {
  width: number
  height: number
  x: number
  y: number
  top: number
  right: number
  bottom: number
  left: number
}
const DEFAULT_BBOX = {
  width: 0,
  height: 0,
  x: 0,
  y: 0,
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
}

export default function useBBox<T extends Element>(): [
  BBox,
  (instance: T | null) => void,
  T | null
] {
  const [element, setElement] = useState<T | null>(null)
  const [bbox, setBBox] = useState<BBox>(DEFAULT_BBOX)
  const pageAnimationStatus = usePageAnimationStatus()

  const set = useCallback(() => {
    setBBox(element?.getBoundingClientRect() ?? DEFAULT_BBOX)
  }, [element])

  const refCallback = useCallback((node) => setElement(node), [])

  useLayoutEffect(() => {
    if (pageAnimationStatus === 'enter') {
      set()
    }
  }, [pageAnimationStatus, set])

  useLayoutEffect(() => {
    set()
    window.addEventListener('resize', set)
    return () => window.removeEventListener('resize', set)
  }, [set])

  return [bbox, refCallback, element]
}
