import { ReactNode } from 'react'
import useRepeatable from 'shared/useRepeatable'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import NumberFormat from 'react-number-format'
import { Minus, Plus } from '@phosphor-icons/react'
import Button from 'components/core/Button'

interface CounterBaseProps {
  value: number
  onChange: (x: number) => void
}

interface CounterProps extends CounterBaseProps {
  children: ReactNode
  min: number
  max: number
  step: number
}

function BaseCounter(props: CounterProps) {
  const { step, min, max, onChange, value } = props

  const decRef = useRepeatable<HTMLButtonElement>({
    value,
    min,
    max,
    step: -step,
    onChange,
  })

  const incRef = useRepeatable<HTMLButtonElement>({
    value,
    min,
    max,
    step,
    onChange,
  })

  return (
    <div className="Counter">
      <Button.Base
        ref={decRef}
        type="button"
        disabled={value <= min}
        className="Button Button--icon-lg Button--secondary Counter-decrease-button"
        data-e2e="dec-btn"
      >
        <Minus />
      </Button.Base>

      {props.children}

      <Button.Base
        ref={incRef}
        type="button"
        disabled={value >= max}
        className="Button Button--icon-lg Button--secondary Counter-increase-button"
        data-e2e="inc-btn"
      >
        <Plus />
      </Button.Base>
    </div>
  )
}

function RiskLevel({ value, onChange }: CounterBaseProps) {
  const intl = useIntl()
  const min = 0
  const max = 10
  const step = 1

  return (
    <BaseCounter
      min={min}
      max={max}
      step={step}
      onChange={onChange}
      value={value}
    >
      <div className="RiskLevelCounter" data-e2e="risk-level-number-input">
        <div className="RiskLevelCounter-wrapper">
          <span className="RiskLevelCounter-value">{value}</span>
          {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
          <span className="RiskLevelCounter-divider">/</span>
          <span>{max}</span>
        </div>
        <div className="RiskLevelCounter-label">
          {intl.formatMessage({ defaultMessage: 'Risk level' })}
        </div>
      </div>
    </BaseCounter>
  )
}

interface ContributionProps extends CounterBaseProps {
  min: number
  max: number
  step: number
}

function Contribution({ min, max, step, value, onChange }: ContributionProps) {
  function handleChange({ floatValue }: any) {
    if (!isNaN(floatValue)) {
      onChange(Math.min(floatValue, max))
    }
  }

  return (
    <BaseCounter
      min={min}
      max={max}
      step={step}
      onChange={onChange}
      value={value}
    >
      <div className="ContributionCounter">
        <NumberFormat
          type="text"
          className="ContributionCounter-input TextInput"
          value={value}
          onValueChange={handleChange}
          inputMode="numeric"
          prefix="$"
          thousandSeparator={true}
          min={min}
          max={max}
          allowNegative={false}
        />
      </div>
    </BaseCounter>
  )
}

interface TradeProps extends CounterBaseProps {
  sharesLabel: string
  tradeMode: Atomic.TradeMode
  price: number
  ownShares?: number
}

function Trade({
  tradeMode,
  sharesLabel,
  price,
  ownShares = 0,
  value,
  onChange,
}: TradeProps) {
  const min = 0
  const max = 100000
  const step = 25
  const intl = useIntl()
  const shares = value / price
  const notEnoughFunds = tradeMode === 'SELL' && shares > ownShares
  const sharesFormatted = intl.formatNumber(value / price, {
    maximumFractionDigits: 3,
  })

  const handleChange = ({ floatValue }: any) => {
    if (!isNaN(floatValue)) {
      tradeMode === 'SELL' ? onChange(floatValue * price) : onChange(floatValue)
    }
  }

  return (
    <BaseCounter
      min={min}
      max={max}
      step={step}
      onChange={onChange}
      value={value}
    >
      <div
        className={classNames('TradeCounter', {
          'TradeCounter--notEnoughFunds': notEnoughFunds,
        })}
      >
        <div className="TradeCounter-wrapper">
          {tradeMode === 'SELL' ? (
            <NumberFormat
              type="text"
              className="TradeCounter-input TextInput"
              value={sharesFormatted}
              onValueChange={handleChange}
              inputMode="decimal"
              allowedDecimalSeparators={['.', ',']}
              min={0}
              allowNegative={false}
            />
          ) : (
            <NumberFormat
              type="text"
              className="TradeCounter-input TextInput"
              value={value}
              onValueChange={handleChange}
              inputMode="numeric"
              prefix="$"
              thousandSeparator={true}
              min={0}
              allowNegative={false}
            />
          )}
        </div>
        <div className="TradeCounter-label">
          {tradeMode === 'BUY' && <span>{sharesFormatted} </span>}
          <span>{sharesLabel}</span>
        </div>
      </div>
    </BaseCounter>
  )
}

const Counter = {
  RiskLevel: RiskLevel,
  Contribution: Contribution,
  Trade: Trade,
}

export default Counter
