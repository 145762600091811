// formatSSN('123456789') -> '123-45-6789'
// formatSSN('') -> ''
export function formatSSN(taxId: string = '') {
  return [taxId.slice(0, 3), taxId.slice(3, 5), taxId.slice(5, 9)]
    .filter((x) => !!x)
    .join('-')
}

// formatEIN('123456789') -> '12-3456789'
// formatEIN('') -> ''
export function formatEIN(taxId: string = '') {
  return [taxId.slice(0, 2), taxId.slice(2, 9)].filter((x) => !!x).join('-')
}

export const TAX_ID_FORMAT_MAP: {
  [key in Atomic.CorporateTaxIdType]?: {
    format: (taxId: string) => string
  }
} = {
  USA_SSN: {
    format: formatSSN,
  },
  USA_EIN: {
    format: formatEIN,
  },
}

export const TAX_ID_VALIDATE_MAP: {
  [key in
    | Atomic.CorporateTaxIdType
    | Atomic.TaxIdType
    | Atomic.ParticipantTaxIdType]?: {
    maxLength: number
    validate: (value: string) => string
  }
} = {
  USA_SSN: {
    maxLength: 9,
    validate: (value: string) =>
      value.length !== 9 || !/\d{9}/.test(value)
        ? 'Please enter 9 digits of SSN'
        : '',
  },
  USA_EIN: {
    maxLength: 9,
    validate: (value: string) =>
      value.length !== 9 || !/\d{9}/.test(value)
        ? 'Please enter 9 digits of EIN'
        : '',
  },
}
