import { useState } from 'react'
import { Page, ActionBar, ExitBtn, BackBtn } from 'components'
import InfoDrawer from 'components/InfoDrawer'
import useUser from 'shared/useUser'
import useSession from 'shared/useSession'
import { motion } from 'framer-motion'
import Counter from 'components/Counter'
import useFeature from 'shared/useFeature'
import LayoutCenter from 'components/layout/LayoutCenter'
import Header from 'components/layout/Header'
import Footer from 'components/layout/Footer'
import usePageAnalytics, {
  useInitPrepopulatedSkippedField,
} from 'shared/usePageAnalytics'
import Button from 'components/core/Button'
import RiskLevelChart from 'components/RiskLevelChart'
import useOnboardingNavigation from 'shared/useOnboardingNavigation'
import useExitConfirm from 'shared/useExitConfirm'
import useWindowSize from 'shared/useWindowSize'
import useViewport from 'shared/useViewport'
import useBBox from 'shared/useBBox'
import useOnboardingState from 'shared/onboarding/useOnboardingState'
import { defineMessage, defineMessages, useIntl } from 'react-intl'
import { CheckCircle } from '@phosphor-icons/react'

export const DEFAULT_RECOMMENDED_RISK_LEVEL = 5

interface Props {
  path: string
}

export const title = defineMessage({
  id: 'page-risk-level.title',
  defaultMessage: 'Choose your portfolio risk level',
})

export const description = defineMessage({
  id: 'page-risk-level.description',
  defaultMessage: 'You can change this at any time.',
})

export const drawerMessages = defineMessages({
  title: {
    defaultMessage: 'Risk level',
  },
  body: {
    defaultMessage:
      'Based upon your unique inputs Atomic Invest’s algorithms create a custom tailored portfolio that’s optimized for you.  You may also choose a different Atomic Invest portfolio but please keep in mind, regardless of the portfolio, there is no guarantee you will achieve your desired investment results.',
  },
})

export const riskLevelMessages = defineMessages({
  conservative: { defaultMessage: 'Conservative' },
  moderate: { defaultMessage: 'Moderate' },
  aggressive: { defaultMessage: 'Aggressive' },
})

export default function RecordRiskLevelPage(props: Props) {
  const intl = useIntl()
  const [state, dispatch] = useOnboardingState()
  const router = useOnboardingNavigation()
  const handleBack = router.goBack(props.path, state)
  const handleNext = router.goNext(props.path, state)
  const handleExit = useExitConfirm()
  const viewport = useViewport()
  const isMbm = viewport.matchSize('mbm')
  const isDesktop = viewport.matchSize('desktop')
  const isTablet = viewport.matchSize('tbm')
  const isSmallTablet = viewport.matchSize('tbs')
  const windowSize = useWindowSize()
  const isCompactHeight = windowSize.height && windowSize.height < 800
  const [bbox, ref] = useBBox<HTMLDivElement>()
  const suppressAnimation = useFeature('dev-suppress-page-animation')
  const session = useSession()
  const user = useUser(session.userId, true)
  const [isDrawerOpen, setDrawerOpen] = useState(false)
  const dispatchAnalytics = usePageAnalytics()
  useInitPrepopulatedSkippedField<number>(
    'sleeve.preferences.risk_score',
    state.riskLevel
  )

  const recommendedRiskLevel =
    user.data?.recommendations?.recommended_risk_score
  const riskLevelNumber =
    state.riskLevel ?? recommendedRiskLevel ?? DEFAULT_RECOMMENDED_RISK_LEVEL

  function handleChange(newValue: number) {
    dispatchAnalytics({
      type: 'SET_FIELD',
      fieldKey: 'sleeve.preferences.risk_score',
    })
    dispatch({
      type: 'action.risk-level/change',
      riskLevel: newValue,
    })
  }
  function handleContinue() {
    dispatch({
      type: 'action.risk-level/continue',
      riskLevel: riskLevelNumber,
    })
    handleNext()
  }

  return (
    <Page id="page-risk-level">
      <ActionBar className="flex justify-between">
        <BackBtn onClick={handleBack} />
        <ExitBtn onClick={handleExit} />
      </ActionBar>

      <LayoutCenter className="tbs:max-w-none">
        <Header
          title={intl.formatMessage(title)}
          description={intl.formatMessage(description)}
          infoIcon={true}
          onInfoIconClick={() => setDrawerOpen(true)}
        />

        <div className="tbs:flex tbs:justify-center">
          <motion.div
            className="flex h-4.5 items-center space-x-1.5 text-primary100"
            animate={{
              opacity: riskLevelNumber === recommendedRiskLevel ? 1 : 0,
            }}
            transition={{ duration: suppressAnimation ? 0 : 0.1 }}
          >
            <CheckCircle className="size-3.5" />
            <span className="text-s4">
              {intl.formatMessage({ defaultMessage: 'Recommended for you' })}
            </span>
          </motion.div>
        </div>

        <div
          ref={ref}
          className="flex w-full justify-center mbm:mt-4 tbs:absolute tbs:left-0 tbs:top-1/2 tbs:mt-0 tbs:-translate-y-3/4 tbm:relative tbm:left-0 tbm:top-0 tbm:mx-0 tbm:-translate-y-0 desktop:mt-8"
        >
          <RiskLevelChart
            width={!isDesktop ? (!isTablet ? bbox.width : 560) : 800}
            height={
              isDesktop
                ? 307
                : isTablet
                ? 183
                : isSmallTablet
                ? (bbox.width + 30) / 3
                : isMbm && !isCompactHeight
                ? bbox.width
                : 210
            }
            riskLevelNumber={riskLevelNumber}
          />
        </div>

        <InfoDrawer
          isOpen={isDrawerOpen}
          onDismiss={() => setDrawerOpen(false)}
          header={intl.formatMessage(drawerMessages.title)}
          body={intl.formatMessage(drawerMessages.body)}
        />

        <Footer>
          <p className="uppercase text-neutral50 text-o2">
            {intl.formatMessage(getQualitativeTag(riskLevelNumber))}
          </p>
          <div className="h-1" />
          <div className="w-full desktop:max-w-column">
            <Counter.RiskLevel
              value={riskLevelNumber}
              onChange={handleChange}
            />
          </div>
          <div className="h-4 desktop:h-6" />
          <Button.Continue
            id="page-risk-level-submit-btn"
            type="button"
            onClick={handleContinue}
          />
        </Footer>
      </LayoutCenter>
    </Page>
  )
}

export function getQualitativeTag(val: number) {
  if (val < 4) {
    return riskLevelMessages.conservative
  }

  if (val < 7) {
    return riskLevelMessages.moderate
  }

  return riskLevelMessages.aggressive
}
