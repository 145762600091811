import {
  createContext,
  ReactNode,
  useContext,
  useLayoutEffect,
  useMemo,
} from 'react'
import hexToRGB from './hexToRGB'
import useGoogleFont from './useGoogleFont'
import useSettings from './useSettings'
import useSession from './useSession'
import { ThemeSchema } from 'schema/theme.schema'

const ThemeContext = createContext<Theme>(ThemeSchema.parse({}))

export default function useTheme() {
  return useContext(ThemeContext)
}

export function ThemeProvider(props: { children: ReactNode }) {
  const settings = useSettings()
  const session = useSession()
  const theme: Theme = useMemo(() => {
    const themeConfig =
      settings.theme === 'custom'
        ? session.customTheme ?? {}
        : settings.theme ?? {}

    return ThemeSchema.parse(themeConfig)
  }, [settings, session.customTheme])
  useApplyTheme(theme)
  return (
    <ThemeContext.Provider value={theme}>
      {props.children}
    </ThemeContext.Provider>
  )
}

export function DefaultThemeProvider(props: { children: ReactNode }) {
  const theme: Theme = ThemeSchema.parse({})
  useApplyTheme(theme)
  return (
    <ThemeContext.Provider value={theme}>
      {props.children}
    </ThemeContext.Provider>
  )
}

function useApplyTheme(theme: Theme) {
  useLayoutEffect(() => {
    for (const key in theme.colors) {
      const value = hexToRGB(theme.colors[key]).join(', ')
      document.documentElement.style.setProperty(`--color-${key}`, value)
    }

    document.documentElement.style.setProperty(
      '--font-weight-primary-regular',
      theme.font['weight-primary-regular']
    )
    document.documentElement.style.setProperty(
      '--font-weight-primary-bold',
      theme.font['weight-primary-bold']
    )

    if (theme.font['family-primary']) {
      if (theme.font['family-primary'] === 'atomic-default-font') {
        document.documentElement.style.setProperty(
          `--font-family-primary`,
          '"Gotham A", "Gotham B", system-ui, -apple-system,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"'
        )
      } else {
        document.documentElement.style.setProperty(
          `--font-family-primary`,
          theme.font['family-primary']
        )
      }
    }
  }, [theme])

  useGoogleFont(theme.font['family-primary'])
}
