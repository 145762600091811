import { scaleLinear } from 'd3-scale'
import { AnimatePresence, motion } from 'framer-motion'
import { MessageDescriptor, useIntl } from 'react-intl'
import useViewport from '../../shared/useViewport'
import useWindowSize from '../../shared/useWindowSize'
import classNames from 'classnames'
import { ReactNode } from 'react'
import useFeature from '../../shared/useFeature'

interface Node {
  x: number
  y: number
  assetClass: Atomic.AssetClass
  quantity: number
}

interface BubbleChartProps {
  width: number
  height: number
  nodes: Node[]
  onBubbleClick: (node: Node) => void
  assetClassToLabelDict: { [key in Atomic.AssetClass]: MessageDescriptor }
  isSettingsPage?: boolean
}

export default function BubbleChart(props: BubbleChartProps) {
  const intl = useIntl()
  const viewport = useViewport()
  const isMbm = viewport.matchSize('mbm')
  const isDesktop = viewport.matchSize('desktop')
  const isTablet = viewport.matchSize('tbm')
  const isSmallTablet = viewport.matchSize('tbs')
  const windowSize = useWindowSize()

  const isCompactHeight = Boolean(windowSize.height && windowSize.height < 800)
  const marginY = isMbm && !isSmallTablet && !isCompactHeight ? 0 : 15

  const scaleFactor = isDesktop
    ? 14
    : isTablet
    ? 11
    : isMbm && !isSmallTablet && !isCompactHeight
    ? 30
    : 10

  const minRadiusFactor =
    props.isSettingsPage && isTablet && !isDesktop
      ? 6
      : isTablet
      ? 5
      : isSmallTablet
      ? 5
      : 10

  const x = scaleLinear().domain([0, 100]).range([0, props.width])
  const y = scaleLinear()
    .domain([0, 100])
    .range([marginY, props.height - marginY])

  return (
    <div
      className="BubbleChart BubbleChart--transparent"
      style={{ width: props.width, height: props.height }}
    >
      <AnimatePresence>
        {props.nodes.map((node: Node) => (
          <div key={node.assetClass} className="BubbleChart-circle-wrapper">
            <Circle
              node={node}
              onClick={() => props.onBubbleClick(node)}
              cx={x(node.x)}
              cy={y(node.y)}
              r={x(minRadiusFactor + node.quantity * scaleFactor)}
            >
              <div className="BubbleChart-text-wrapper">
                <div className="BubbleChart-text-percent">
                  {intl.formatNumber(node.quantity, { style: 'percent' })}
                </div>
                <div className="BubbleChart-text-asset-class">
                  {intl.formatMessage(
                    props.assetClassToLabelDict[node.assetClass]
                  )}
                </div>
              </div>
            </Circle>
          </div>
        ))}
      </AnimatePresence>
    </div>
  )
}

interface CircleProps {
  node: Node
  onClick: () => void
  children: ReactNode
  cx: number
  cy: number
  r: number
}

function Circle(props: CircleProps) {
  const suppressAnimation = useFeature('dev-suppress-page-animation')

  return (
    <motion.div
      key={props.node.assetClass + 'circle'}
      onClick={props.onClick}
      animate={{
        opacity: 1,
        left: props.cx - props.r,
        top: props.cy - props.r,
        width: props.r * 2,
        height: props.r * 2,
      }}
      initial={{
        left: props.cx - props.r * 0.8,
        top: props.cy - props.r * 0.8,
        width: props.r * 0.8 * 2,
        height: props.r * 0.8 * 2,
        opacity: 0,
      }}
      exit={{
        left: props.cx - props.r * 0.8,
        top: props.cy - props.r * 0.8,
        width: props.r * 0.8 * 2,
        height: props.r * 0.8 * 2,
        opacity: 0,
      }}
      transition={{ bounce: 0, duration: suppressAnimation ? 0 : 0.2 }}
      className={classNames('BubbleChart-circle', {
        'BubbleChart-circle-COMMON_STOCK':
          props.node.assetClass === 'COMMON_STOCK',
        'BubbleChart-circle-CRYPTO': props.node.assetClass === 'CRYPTO',
        'BubbleChart-circle-BOND': props.node.assetClass === 'BOND',
        'BubbleChart-circle-FOREIGN_STOCK':
          props.node.assetClass === 'FOREIGN_STOCK',
        'BubbleChart-circle-FOREIGN_BOND':
          props.node.assetClass === 'FOREIGN_BOND',
        'BubbleChart-circle-CASH': props.node.assetClass === 'CASH',
        'BubbleChart-circle-REAL_ESTATE':
          props.node.assetClass === 'REAL_ESTATE',
        'BubbleChart-circle-OTHER': props.node.assetClass === 'OTHER',
      })}
    >
      {props.children}
    </motion.div>
  )
}
