export default function createVerifications(
  userId: string,
  enabledOnBoardingConfirmErrors?: boolean
) {
  return (fetch: Function) => {
    return fetch(`/verifications`, {
      method: 'POST',
      body: JSON.stringify({ user_id: userId }),
    }).catch((error: any) => {
      // Ignore KYC/AML error
      if (error?.code === 'U0006' || !enabledOnBoardingConfirmErrors) {
        return
      } else {
        throw error
      }
    })
  }
}
