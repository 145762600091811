import { useRef } from 'react'
import NumberFormat from 'react-number-format'
import { CustomDate } from 'shared/custom-date'
import TextField from '../core/TextField'
import { IntlShape } from 'react-intl'

interface DateFieldProps {
  value?: CustomDate
  onChange: (value: CustomDate) => void
  error?: string
  label: string
  id: string
  disabled?: boolean
}

function formatDate(val: string) {
  let month = val.substring(0, 2)
  let date = val.substring(2, 4)
  let year = val.substring(4, 8)

  return (
    month +
    (month.length === 2 ? '/' + date : '') +
    (date.length === 2 ? '/' + year : '')
  )
}

export function validateDate(value?: CustomDate, intl?: IntlShape) {
  const dataStr = `${value?.year}-${value?.month}-${value?.day}`
  if (!value || dataStr.length !== 10) {
    return intl
      ? intl.formatMessage({
          defaultMessage: 'Please enter date in format MM/DD/YYYY',
        })
      : 'Please enter date in format MM/DD/YYYY'
  }

  const parsedValue = new Date(dataStr)
  if (parsedValue.toString() === 'Invalid Date') {
    return intl
      ? intl.formatMessage({ defaultMessage: 'Please enter a valid date' })
      : 'Please enter a valid date'
  }
}

export default function DateField({
  value = { year: '', month: '', day: '' },
  label,
  onChange,
  error,
  id,
  disabled,
}: DateFieldProps) {
  const input = useRef<HTMLInputElement>()
  return (
    <TextField
      className="DateField"
      errorMessage={error}
      forceFilledState={Boolean(value.year && value.month && value.day)}
      disabled={disabled}
    >
      <NumberFormat
        getInputRef={input}
        format={formatDate}
        placeholder="MM/DD/YYYY"
        className="TextInput"
        id={id}
        name={id}
        value={`${value.month}/${value.day}/${value.year}`}
        inputMode="numeric"
        autoComplete="off"
        onValueChange={({ formattedValue }: any) => {
          const [month, day, year] = formattedValue.split('/')
          setTimeout(() => {
            input?.current?.setSelectionRange(
              formattedValue.length,
              formattedValue.length
            )
            input?.current?.focus()
          })
          onChange({ month, day, year })
        }}
        disabled={disabled}
      />
      <TextField.Label htmlFor={id}>{label}</TextField.Label>
    </TextField>
  )
}
