import useSWR from 'swr'
import useSession from './useSession'

export default function useUser(userId?: string, revalidateIfStale = false) {
  const session = useSession()

  let swrOptions = {}
  if (session.sessionType === 'onboarding') {
    // for onboarding disable revalidation
    // the problem - we always call TWO hooks useRemoteUserFetcher and useLocalFetcher
    // and useRemoteUserFetcher call useUser hook
    // which causes redundant API call to users/ endpoint
    swrOptions = {
      revalidateIfStale,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  }

  return useSWR<Atomic.User>(
    userId && userId !== 'unknown' ? '/users/' + userId : null,
    swrOptions
  )
}
