import { backOff } from 'exponential-backoff'
import confirmAccount from './confirmAccount'

export default function confirmOnboarding(
  userId: string,
  accountId: string,
  isIndividualAccount: boolean,
  verificationStatus: any,
  enabledOnBoardingConfirmErrors?: boolean
) {
  return async (fetch: Function) => {
    await fetch(`/users/${userId}:confirm-onboarding`, {
      method: 'POST',
    }).catch((error: any) => {
      // Ignore KYC/AML error
      if (error?.code === 'U0006' || !enabledOnBoardingConfirmErrors) {
        return
      } else {
        throw error
      }
    })

    if (!isIndividualAccount) {
      await confirmAccount(accountId, enabledOnBoardingConfirmErrors)(fetch)
    }
    if (
      verificationStatus === 'processing' ||
      verificationStatus === 'successful'
    ) {
      try {
        await backOff(() => waitForPendingAccount(fetch, accountId), {
          delayFirstAttempt: true,
          startingDelay: 200,
          numOfAttempts: 5,
        })
      } catch (error) {
        console.log(error)
      }
    }
  }
}

async function waitForPendingAccount(fetch: Function, accountId: string) {
  const data = await fetch('/accounts/' + accountId)
  if (data.account_status === 'NOT_STARTED') {
    throw new Error('Account is still NOT_STARTED')
  }
}
