export default function confirmAccount(
  accountId: string,
  enabledOnBoardingConfirmErrors?: boolean
) {
  return (fetch: Function) => {
    return fetch(`/confirm`, {
      method: 'POST',
      body: JSON.stringify({
        account_id: accountId,
      }),
    }).catch((error: any) => {
      if (!enabledOnBoardingConfirmErrors) {
        return
      } else {
        throw error
      }
    })
  }
}
