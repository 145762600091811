import { useRef, useState, useCallback, useEffect, useMemo } from 'react'
import { scaleThreshold } from 'd3-scale'

interface UseRepeatableArgs {
  value: number
  min: number
  max: number
  step: number
  onChange: (x: number) => void
}

export default function useRepeatable<T extends HTMLElement>({
  value,
  min,
  max,
  step,
  onChange,
}: UseRepeatableArgs) {
  const ref = useRef<T>(null)
  const [pressedTime, setPressedTime] = useState(0)
  const scale = useMemo(
    () =>
      scaleThreshold()
        .domain([0e3, 1e3, 2e3, 3e3, 4e3, 5e3, 6e3, 7e3])
        .range([1, 2, 4, 10, 20, 40, 200, 400].map((x) => x * step)),
    [step]
  )

  const handleClick = useCallback(() => {
    if (value === min || value === max) {
      setPressedTime(0)
    }

    if (pressedTime > 0) {
      const timePassedMs = Date.now() - pressedTime
      const speedUpStep = scale(timePassedMs)
      onChange(Math.max(Math.min(value + speedUpStep, max), min))
    } else {
      onChange(Math.max(Math.min(value + step, max), min))
    }
  }, [value, min, max, step, onChange, pressedTime, scale])

  // isPressed used here to prevent double click on mobile
  // due to both mousedown and touchstart events run at the same time
  const disabledMouseDown = useRef(false)

  // Manage event handlers
  useEffect(() => {
    const button = ref.current

    function handleTouchStart() {
      disabledMouseDown.current = true
      handleClick()
      setPressedTime(Date.now())
    }

    function handleMouseDown() {
      if (!disabledMouseDown.current) {
        handleClick()
        setPressedTime(Date.now())
      }
    }

    function handleRelease() {
      setPressedTime(0)
    }

    button?.addEventListener('touchstart', handleTouchStart, false)
    button?.addEventListener('mousedown', handleMouseDown, false)
    button?.addEventListener('touchcancel', handleRelease, false)
    button?.addEventListener('touchend', handleRelease, false)
    button?.addEventListener('mouseup', handleRelease, false)
    button?.addEventListener('mouseout', handleRelease, false)

    return () => {
      button?.removeEventListener('touchstart', handleTouchStart, false)
      button?.removeEventListener('mousedown', handleMouseDown, false)
      button?.removeEventListener('touchcancel', handleRelease, false)
      button?.removeEventListener('touchend', handleRelease, false)
      button?.removeEventListener('mouseup', handleRelease, false)
      button?.removeEventListener('mouseout', handleRelease, false)
    }
  }, [handleClick])

  // Manager timers
  useEffect(() => {
    const delayMs = 500
    const defaultMs = 32
    var timer: any = null

    function tick(ms: number) {
      timer = setTimeout(() => {
        if (pressedTime !== 0) {
          // const timePassedMs = Date.now() - pressedTime
          // const timePassedSec = Math.floor(timePassedMs / 1000)
          // const minMs = 30

          // tick(Math.max(defaultMs - timePassedSec * 20, minMs))
          tick(defaultMs)
        }

        if (Date.now() - pressedTime > delayMs) {
          handleClick()
        }
      }, ms)
    }

    if (pressedTime > 0 && !timer) {
      tick(defaultMs)
    }

    if (pressedTime === 0 && timer) {
      clearTimeout(timer)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [pressedTime, handleClick])

  // Stop time when min/max value reached
  useEffect(() => {
    if (value === min || value === max) {
      setPressedTime(0)
    }
  }, [value, min, max])

  return ref
}
