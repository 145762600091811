import { ReactNode, useState } from 'react'
import InfoDrawer from './InfoDrawer'
import BubbleChart from './core/BubbleChart'
import useViewport from '../shared/useViewport'
import useFeature from '../shared/useFeature'
import { defineMessages } from 'react-intl'
import { useIntl } from 'react-intl'

export const assetClassToLabelDict = defineMessages<Atomic.AssetClass>({
  COMMON_STOCK: { defaultMessage: 'US Stocks' },
  BOND: { defaultMessage: 'Bonds' },
  FOREIGN_STOCK: { defaultMessage: 'Foreign Stocks' },
  FOREIGN_BOND: { defaultMessage: 'Foreign Bonds' },
  CASH: { defaultMessage: 'Cash' },
  REAL_ESTATE: { defaultMessage: 'Real Estate' },
  OTHER: { defaultMessage: 'Other' },
  CRYPTO: { defaultMessage: 'Crypto' },
  MUTUAL_FUND: { defaultMessage: 'Money market funds' },
})

export const assetClassToInfoBodyDict = defineMessages<Atomic.AssetClass>({
  COMMON_STOCK: {
    defaultMessage:
      'A diversified basket of 80 small, mid and large cap US stocks.',
  },
  BOND: {
    defaultMessage:
      'A diversified basket of ETFs holding high grade US government and corporate bonds.',
  },
  FOREIGN_STOCK: {
    defaultMessage: 'A diversified basket of 40 ex-US blue-chip stocks.',
  },
  FOREIGN_BOND: {
    defaultMessage: `
      <p>
        A portfolio of global government and corporate bonds that hedges equity
        risk.
      </p>

      <p>
        A foreign bond is a bond which is issued in several countries at the
        same time. It is typically issued by a large multinational corporation
        or sovereign entity with a high credit rating. By offering the bond to
        many investors, a global issuance can reduce borrowing cost.
      </p>`,
  },
  REAL_ESTATE: {
    defaultMessage: '--PLACEHOLDER--',
  },
  CASH: {
    defaultMessage: '--PLACEHOLDER--',
  },
  OTHER: {
    defaultMessage: '--PLACEHOLDER--',
  },
  CRYPTO: {
    defaultMessage: '--PLACEHOLDER--',
  },
  MUTUAL_FUND: {
    defaultMessage: '--PLACEHOLDER--',
  },
})

function withPosition(
  type: string,
  exposure: number,
  xArg: [number, number],
  yArg: [number, number]
) {
  const [xSize, xRange] = xArg
  const [ySize, yRange] = yArg
  const x = xSize + xRange * exposure
  const y = ySize + yRange * exposure
  return [type, exposure, x, y]
}

// prettier-ignore
export const RISK_ASSETS_STANDARD = [
  [withPosition('BOND', 0.71, [90, -40], [90, -40]), withPosition('COMMON_STOCK', 0.29, [10, 40], [10, 40])],
  [withPosition('BOND', 0.62, [90, -40], [90, -40]), withPosition('COMMON_STOCK', 0.38, [10, 40], [10, 40])],
  [withPosition('BOND', 0.54, [90, -40], [90, -40]), withPosition('COMMON_STOCK', 0.46, [10, 40], [10, 40])],
  [withPosition('BOND', 0.47, [90, -40], [90, -40]), withPosition('COMMON_STOCK', 0.53, [10, 40], [10, 40])],
  [withPosition('BOND', 0.40, [90, -40], [90, -40]), withPosition('COMMON_STOCK', 0.60, [10, 40], [10, 40])],
  [withPosition('BOND', 0.34, [90, -40], [90, -40]), withPosition('COMMON_STOCK', 0.66, [10, 40], [10, 40])],
  [withPosition('BOND', 0.27, [90, -40], [90, -40]), withPosition('COMMON_STOCK', 0.73, [10, 40], [10, 40])],
  [withPosition('BOND', 0.21, [90, -40], [90, -40]), withPosition('COMMON_STOCK', 0.79, [10, 40], [10, 40])],
  [withPosition('BOND', 0.15, [90, -40], [90, -40]), withPosition('COMMON_STOCK', 0.85, [10, 40], [10, 40])],
  [withPosition('BOND', 0.09, [90, -40], [90, -40]), withPosition('COMMON_STOCK', 0.91, [10, 40], [10, 40])],
  [withPosition('BOND', 0.03, [90, -40], [90, -40]), withPosition('COMMON_STOCK', 0.97, [10, 40], [10, 40])],
]

// prettier-ignore
export const RISK_ASSETS_STANDARD_DESKTOP = [
  [withPosition('BOND', 0.71, [80, -30], [50, 0]), withPosition('COMMON_STOCK', 0.29, [20, 30], [50, 0])],
  [withPosition('BOND', 0.62, [80, -30], [50, 0]), withPosition('COMMON_STOCK', 0.38, [20, 30], [50, 0])],
  [withPosition('BOND', 0.54, [80, -30], [50, 0]), withPosition('COMMON_STOCK', 0.46, [20, 30], [50, 0])],
  [withPosition('BOND', 0.47, [80, -30], [50, 0]), withPosition('COMMON_STOCK', 0.53, [20, 30], [50, 0])],
  [withPosition('BOND', 0.40, [80, -30], [50, 0]), withPosition('COMMON_STOCK', 0.60, [20, 30], [50, 0])],
  [withPosition('BOND', 0.34, [80, -30], [50, 0]), withPosition('COMMON_STOCK', 0.66, [20, 30], [50, 0])],
  [withPosition('BOND', 0.27, [80, -30], [50, 0]), withPosition('COMMON_STOCK', 0.73, [20, 30], [50, 0])],
  [withPosition('BOND', 0.21, [80, -30], [50, 0]), withPosition('COMMON_STOCK', 0.79, [20, 30], [50, 0])],
  [withPosition('BOND', 0.15, [80, -30], [50, 0]), withPosition('COMMON_STOCK', 0.85, [20, 30], [50, 0])],
  [withPosition('BOND', 0.09, [80, -30], [50, 0]), withPosition('COMMON_STOCK', 0.91, [20, 30], [50, 0])],
  [withPosition('BOND', 0.03, [80, -30], [50, 0]), withPosition('COMMON_STOCK', 0.97, [20, 30], [50, 0])],
]

// prettier-ignore
export const RISK_ASSETS_CRYPTO = [
  [['BOND', 1.0, 50, 50]],
  [['BOND', 0.89, 58, 54], ['COMMON_STOCK', 0.09, 24, 14], ['CRYPTO', 0.02, 12, 36]],
  [['BOND', 0.79, 58, 58], ['COMMON_STOCK', 0.19, 18, 18], ['CRYPTO', 0.02, 12, 46]],
  [['BOND', 0.69, 62, 62], ['COMMON_STOCK', 0.29, 22, 22], ['CRYPTO', 0.02, 18, 54]],
  [['BOND', 0.59, 66, 66], ['COMMON_STOCK', 0.39, 26, 26], ['CRYPTO', 0.02, 22, 64]],
  [['BOND', 0.49, 70, 70], ['COMMON_STOCK', 0.49, 30, 30], ['CRYPTO', 0.02, 30, 70]],
  [['BOND', 0.39, 74, 74], ['COMMON_STOCK', 0.59, 34, 34], ['CRYPTO', 0.02, 36, 78]],
  [['BOND', 0.29, 78, 78], ['COMMON_STOCK', 0.69, 38, 38], ['CRYPTO', 0.02, 42, 86]],
  [['BOND', 0.19, 82, 76], ['COMMON_STOCK', 0.79, 38, 42], ['CRYPTO', 0.02, 54, 86]],
  [['BOND', 0.09, 86, 76], ['COMMON_STOCK', 0.89, 46, 40], ['CRYPTO', 0.02, 60, 88]],
  [['COMMON_STOCK', 1.0, 50, 50]],
]

// prettier-ignore
export const RISK_ASSETS_CRYPTO_DESKTOP = [
  [['BOND', 1.0, 50, 50]],
  [['BOND', 0.89, 31, 50], ['COMMON_STOCK', 0.09, 61, 50], ['CRYPTO', 0.02, 80, 50]],
  [['BOND', 0.79, 29, 50], ['COMMON_STOCK', 0.19, 59, 50], ['CRYPTO', 0.02, 79, 50]],
  [['BOND', 0.69, 28, 50], ['COMMON_STOCK', 0.29, 58, 50], ['CRYPTO', 0.02, 78, 50]],
  [['BOND', 0.59, 27, 50], ['COMMON_STOCK', 0.39, 56, 50], ['CRYPTO', 0.02, 77, 50]],
  [['BOND', 0.49, 26, 50], ['COMMON_STOCK', 0.49, 55, 50], ['CRYPTO', 0.02, 76, 50]],
  [['BOND', 0.39, 25, 50], ['COMMON_STOCK', 0.59, 53, 50], ['CRYPTO', 0.02, 75, 50]],
  [['BOND', 0.29, 24, 50], ['COMMON_STOCK', 0.69, 51, 50], ['CRYPTO', 0.02, 74, 50]],
  [['BOND', 0.19, 22, 50], ['COMMON_STOCK', 0.79, 49, 50], ['CRYPTO', 0.02, 73, 50]],
  [['BOND', 0.09, 22, 50], ['COMMON_STOCK', 0.89, 48, 50], ['CRYPTO', 0.02, 73, 50]],
  [['COMMON_STOCK', 1.0, 50, 50]],
]

export interface CategoryNode extends Category {
  x: number
  y: number
  r?: number
}

export default function RiskLevelChart({
  width,
  height,
  isSettingsPage,
  riskLevelNumber,
}: any) {
  const intl = useIntl()
  const isSmallTablet = useViewport().matchSize('tbs')
  const hasCryptoFeature = useFeature('allow-crypto')
  let exposure
  if (!isSmallTablet) {
    exposure = hasCryptoFeature ? RISK_ASSETS_CRYPTO : RISK_ASSETS_STANDARD
  } else {
    exposure = hasCryptoFeature
      ? RISK_ASSETS_CRYPTO_DESKTOP
      : RISK_ASSETS_STANDARD_DESKTOP
  }

  const riskAssetsExposures = new Map<number, CategoryNode[]>(
    exposure.map((data: any, index) => [
      index,
      data.map((d: [string, number, number, number]) => ({
        assetClass: d[0],
        quantity: d[1],
        x: d[2],
        y: d[3],
      })),
    ])
  )

  const [drawerInfo, setDrawerInfo] = useState<{
    isOpen: boolean
    header: string
    body: string | ReactNode
  }>({ isOpen: false, header: '', body: '' })

  function closeDrawer() {
    setDrawerInfo({ ...drawerInfo, isOpen: false })
  }

  return (
    <>
      {width > 0 && (
        <BubbleChart
          width={width}
          height={height}
          isSettingsPage={isSettingsPage}
          nodes={riskAssetsExposures.get(riskLevelNumber) || []}
          onBubbleClick={(node) => {
            setDrawerInfo({
              isOpen: true,
              header: intl.formatMessage(
                assetClassToLabelDict[node.assetClass]
              ),
              body: intl.formatMessage(
                assetClassToInfoBodyDict[node.assetClass],
                {
                  p: (str) => <p>{str}</p>,
                }
              ),
            })
          }}
          assetClassToLabelDict={assetClassToLabelDict}
        />
      )}
      <InfoDrawer
        isOpen={drawerInfo.isOpen}
        header={drawerInfo.header}
        body={<div className="space-y-4">{drawerInfo.body}</div>}
        onDismiss={closeDrawer}
      />
    </>
  )
}
