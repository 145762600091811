import { createContext, useContext } from 'react'
import { Dispatch } from 'react'
import { createDraft, finishDraft } from 'immer'
import { ContributionSchedule } from 'types/enums'
import { CustomDate, toStringDate } from 'shared/custom-date'
import upsertSleeve from './upsertSleeve'
import useFetch from '../useFetch'
import upsertSIP from './upsertSIP'
import { formatSSN } from 'shared/taxIdHelpers'
import { DEFAULT_RECOMMENDED_RISK_LEVEL } from 'views/retail/pages/RecordRiskLevelPage'
import uniqueId from 'lodash/uniqueId'
import cloneDeep from 'lodash/cloneDeep'
import isNumber from 'lodash/isNumber'
import deleteIfAvailableSIP from './deleteIfAvailableSIP'

export interface PersonalInfo {
  firstName?: string
  lastName?: string
  birthday?: CustomDate
  ssn?: string
}

export interface TaxProfile {
  taxIdType?: Atomic.TaxIdType
  taxId?: string
  countryOfTaxResidence?: string
  personaInquiryStarted?: boolean
}

export interface ContactInfo {
  email?: string
  phone?: string
}

export interface Restrictions {
  ticker_symbol: string
}

export type Action =
  | { type: 'action/reset'; state: OnboardingState }
  | { type: 'action.citizenship/next' }
  | {
      type: 'action.citizenship/change'
      citizenship: string[]
    }
  | { type: 'action.tax-profile/change-country'; countryCode: string }
  | { type: 'action.tax-profile/change-tax-id'; taxId: string }
  | { type: 'action.tax-profile/submit' }
  | { type: 'action.priority/change'; priority: Atomic.InvestmentPriority }
  | {
      type: 'action.time-horizon/change'
      timeHorizon: Atomic.InvestmentHorizon
    }
  | {
      type: 'action.downturn-reaction/change'
      downturnReaction: Atomic.DownturnReaction
    }
  | { type: 'action.gross-income/change'; annualGrossIncome?: string }
  | { type: 'action.gross-income/submit' }
  | {
      type: 'action.investment-principles/change'
      investmentPrinciples: string[]
    }
  | {
      type: 'action.risk-level/change'
      riskLevel: number
    }
  | {
      type: 'action.risk-level/continue'
      riskLevel: number
    }
  | { type: 'action.initial-contribution/change'; value: string }
  | { type: 'action.initial-contribution/next' }
  | { type: 'action.investment-plan/change-amount'; value: number }
  | {
      type: 'action.investment-plan/change-schedule'
      schedule: string
    }
  | { type: 'action.investment-plan/skip' }
  | { type: 'action.investment-plan/next' }
  | { type: 'action.contact-info/submit'; email: string; phone: string }
  | {
      type: 'action.personal-info/submit'
      firstName: string
      lastName: string
      birthday?: CustomDate
      ssn?: string
    }
  | {
      type: 'action.trusted-contact/next'
      trustedContact: Atomic.TrustedContact
    }
  | {
      type: 'action.employment-status/change'
      employment: Atomic.EmpStatus
    }
  | {
      type: 'action.employment-status/submit'
    }
  | {
      type: 'action.employer/change'
      employer: Atomic.Employer
    }
  | {
      type: 'action.employer/submit'
    }
  | {
      type: 'action.employer-address/submit'
    }
  | {
      type: 'action.affiliations/change'
      affiliations: Atomic.Affiliations[]
    }
  | {
      type: 'action.affiliations/submit'
    }
  | { type: 'action.us-status/change'; status: Atomic.ResidenceStatus }
  | {
      type: 'action.us-status/submit'
    }
  | {
      type: 'action.legal-address/change'
      address?: Atomic.Address
    }
  | {
      type: 'action.legal-address/submit'
    }
  | {
      type: 'action.external-account/change'
      account: string
    }
  | {
      type: 'action.kyc.personal-info/submit'
      firstName?: string
      lastName?: string
      birthday?: CustomDate
      ssn?: string
    }
  | {
      type: 'action/select-account'
      portfolioType: PortfolioType
    }
  | {
      type: 'action/change-account'
      accountType: Atomic.AccountType
      portfolioType: PortfolioType
      accountId: string
      accountList: Atomic.Account[]
      participantList?: Atomic.Participant[]
      sleeveType?: Atomic.SleeveType
    }
  | {
      type: 'action/add-primary-beneficiary'
      clientId: string
      participant: Partial<Atomic.Participant>
    }
  | {
      type: 'action/change-beneficiary-relationship'
      clientId: string
      relationship: string
    }
  | {
      type: 'action/change-beneficiary-identity'
      clientId: string
      firstName: string
      lastName: string
      birthDate: string
      ownershipPercentage: number
    }
  | {
      type: 'action/change-beneficiary-contacts'
      clientId: string
      email: string
    }
  | {
      type: 'action/delete-beneficiary'
      clientId: string
    }
  | {
      type: 'action.marital-status/change'
      status: Atomic.MaritalStatus
    }
  | {
      type: 'action.marital-status/submit'
    }

export type Command =
  | { type: 'command.user/sync'; body: any }
  | {
      type: 'command.sleeve/sync'
      riskLevel: number
      accountType: Atomic.AccountType
      portfolioType: PortfolioType
      sleeveType?: Atomic.SleeveType
    }
  | { type: 'command.history/push'; path: string }
  | {
      type: 'command.sip/sync'
      accountType: Atomic.AccountType
      sleeveType?: Atomic.SleeveType
      contribution: number
      schedule: ContributionSchedule
      riskLevel?: number
      externalAccountId?: string
      portfolioType: PortfolioType
    }
  | {
      type: 'command.sip/remove'
      accountType: Atomic.AccountType
      sleeveType?: Atomic.SleeveType
      portfolioType: PortfolioType
    }
  | {
      type: 'command.participant/sync'
      serverId: string
      body: Partial<Atomic.Participant>
    }
  | {
      type: 'command.participant/delete'
      serverId: string
    }

type Occupation = {
  title: string
  industry: string
}

export interface OnboardingState {
  address?: Atomic.Address
  affiliations?: Atomic.Affiliations[]
  downturnReaction?: Atomic.DownturnReaction
  investmentPreference?: Atomic.InvestmentPriority
  timeHorizon?: Atomic.InvestmentHorizon
  investmentPrinciples?: string[]
  annualGrossIncome?: string
  riskLevel?: number
  initialContribution?: string
  recurringContribution?: {
    contribution: number
    schedule: ContributionSchedule
    min: number
    max: number
    step: number
  }
  personalInfo?: PersonalInfo
  contactInfo?: ContactInfo
  employment?: Atomic.EmpStatus
  employer?: Atomic.Employer
  restrictions?: Restrictions[]
  trustedContact?: Atomic.TrustedContact
  usStatus?: Atomic.ResidenceStatus
  occupation?: Occupation
  externalAccountId?: string
  taxProfile?: TaxProfile
  maritalStatus?: Atomic.MaritalStatus
  citizenship: string[]

  participantMap: Record<string, Partial<Atomic.Participant>>
  clientServerIdMap: Record<string, string>
  accountList: Atomic.Account[]
  accountType: Atomic.AccountType
  externalAccounts: Atomic.ExternalAccount[]
  portfolioType: PortfolioType
  accountId?: string
  sleeveType?: Atomic.SleeveType

  settings: {
    citizenship_limit: number
    investment_plan: {
      contribution: number
      schedule: ContributionSchedule
      min: number
      max: number
      step: number
    }
  }
}

type Context = [OnboardingState, Dispatch<Action>]
type Reducer = (
  state: OnboardingState,
  action: Action
) => [OnboardingState, Command[]]

export const OnboardingStateContext = createContext<any>(undefined)

export default function useOnboardingState(): Context {
  const context = useContext(OnboardingStateContext)
  if (!context) {
    throw new Error('OnboardingStateContext is not defined')
  }
  return context
}

export const reduce: Reducer = (state, action) => {
  const draft = createDraft(state)
  const command: Command[] = []

  if (action.type === 'action/reset') {
    return [action.state, command]
  }

  switch (action.type) {
    case 'action.citizenship/next': {
      if (draft.citizenship.includes('USA')) {
        if (draft.personalInfo?.ssn) {
          delete draft.personalInfo.ssn
        }

        if (draft.taxProfile && draft.taxProfile.taxIdType !== 'USA_SSN') {
          draft.taxProfile.taxIdType = 'USA_SSN'
          delete draft.taxProfile.taxId
        } else {
          draft.taxProfile = {
            taxIdType: 'USA_SSN',
          }
        }
      }
      break
    }

    case 'action.citizenship/change': {
      if (action.citizenship.length <= draft.settings.citizenship_limit) {
        draft.citizenship = action.citizenship
        delete draft.taxProfile?.countryOfTaxResidence
        delete draft.taxProfile?.taxIdType
        delete draft.taxProfile?.taxId
        delete draft.usStatus
        command.push({
          type: 'command.user/sync',
          body: {
            identity: {
              country_of_citizenship: action.citizenship,
            },
          },
        })
      }
      break
    }

    case 'action.tax-profile/change-country': {
      draft.taxProfile = {
        countryOfTaxResidence: action.countryCode,
      }
      break
    }

    case 'action.tax-profile/change-tax-id': {
      draft.taxProfile = {
        ...draft.taxProfile,
        taxId: action.taxId,
      }
      break
    }

    case 'action.tax-profile/submit': {
      if (draft.taxProfile) {
        command.push({
          type: 'command.user/sync',
          body: {
            tax_profile: {
              country_of_tax_residence: draft.taxProfile.countryOfTaxResidence,
              tax_id_type: 'OTHER_TAX_ID',
              tax_id: draft.taxProfile.taxId,
            },
          },
        })
      }
      break
    }

    case 'action.priority/change': {
      draft.investmentPreference = action.priority
      command.push({
        type: 'command.user/sync',
        body: {
          financial_profile: {
            investment_priority: action.priority,
          },
        },
      })
      break
    }

    case 'action.time-horizon/change': {
      draft.timeHorizon = action.timeHorizon
      command.push({
        type: 'command.user/sync',
        body: {
          financial_profile: {
            investment_horizon: action.timeHorizon,
          },
        },
      })
      break
    }

    case 'action.downturn-reaction/change': {
      draft.downturnReaction = action.downturnReaction
      command.push({
        type: 'command.user/sync',
        body: {
          financial_profile: {
            downturn_reaction: action.downturnReaction,
          },
        },
      })
      break
    }

    case 'action.gross-income/change': {
      draft.annualGrossIncome = action.annualGrossIncome
      break
    }

    case 'action.gross-income/submit': {
      command.push({
        type: 'command.user/sync',
        body: {
          financial_profile: {
            annual_gross_income: {
              amount: Number(draft.annualGrossIncome),
              currency: 'USD',
            },
          },
        },
      })
      break
    }

    case 'action.investment-principles/change': {
      draft.investmentPrinciples = action.investmentPrinciples
      command.push({
        type: 'command.user/sync',
        body: {
          investment_principles: action.investmentPrinciples,
        },
      })
      break
    }

    case 'action.risk-level/change': {
      draft.riskLevel = action.riskLevel
      break
    }

    case 'action.risk-level/continue': {
      command.push({
        type: 'command.sleeve/sync',
        riskLevel: action.riskLevel,
        accountType: state.accountType,
        portfolioType: state.portfolioType,
        sleeveType: state.sleeveType,
      })
      break
    }

    case 'action.initial-contribution/change': {
      draft.initialContribution = action.value
      break
    }

    case 'action.initial-contribution/next': {
      break
    }

    case 'action.investment-plan/change-amount': {
      if (draft.recurringContribution) {
        draft.recurringContribution.contribution = action.value
      } else {
        // TODO: handle undefined recurring contribution
        draft.recurringContribution = {
          ...draft.settings.investment_plan,
          contribution: action.value,
        }
      }
      break
    }

    case 'action.investment-plan/change-schedule': {
      // TODO: validate schedule
      const schedule = action.schedule as ContributionSchedule
      const max = action.schedule === ContributionSchedule.WEEKLY ? 2000 : 5000
      if (draft.recurringContribution) {
        draft.recurringContribution.schedule = schedule
        draft.recurringContribution.max = max
      } else {
        draft.recurringContribution = {
          ...draft.settings.investment_plan,
          schedule,
          max,
        }
      }
      if (draft.recurringContribution.contribution > max) {
        draft.recurringContribution.contribution = max
      }
      break
    }

    case 'action.investment-plan/skip': {
      draft.recurringContribution = undefined
      command.push({
        type: 'command.sip/remove',
        accountType: state.accountType,
        sleeveType: state.sleeveType,
        portfolioType: state.portfolioType,
      })
      break
    }

    case 'action.investment-plan/next': {
      if (!draft.recurringContribution) {
        draft.recurringContribution = draft.settings.investment_plan
      }
      command.push({
        type: 'command.sip/sync',
        accountType: state.accountType,
        sleeveType: state.sleeveType,
        contribution: draft.recurringContribution.contribution,
        schedule: draft.recurringContribution.schedule,
        ...(isNumber(draft.riskLevel) && { riskLevel: draft.riskLevel }),
        ...(draft.externalAccountId && {
          externalAccountId: draft.externalAccountId,
        }),
        portfolioType: state.portfolioType,
      })
      break
    }

    case 'action.contact-info/submit': {
      draft.contactInfo = {
        email: action.email,
        phone: action.phone,
      }
      command.push({
        type: 'command.user/sync',
        body: {
          identity: {
            email: action.email,
            phone_number: action.phone.replace('+', ''),
          },
        },
      })
      break
    }

    case 'action.personal-info/submit': {
      draft.personalInfo = {
        firstName: action.firstName,
        lastName: action.lastName,
        birthday: action.birthday,
        ...(action.ssn && { ssn: action.ssn }),
      }
      command.push({
        type: 'command.user/sync',
        body: {
          identity: {
            first_name: action.firstName,
            last_name: action.lastName,
            date_of_birth: toStringDate(action.birthday),
          },
          ...(action?.ssn &&
            action.ssn !== '*********' && {
              tax_profile: {
                tax_id: formatSSN(action.ssn),
                country_of_tax_residence: 'USA',
                tax_id_type: 'USA_SSN',
              },
            }),
        },
      })
      break
    }

    case 'action.trusted-contact/next': {
      draft.trustedContact = {
        ...action.trustedContact,
      }
      command.push({
        type: 'command.user/sync',
        body: {
          trusted_contact: {
            first_name: action.trustedContact.firstName,
            last_name: action.trustedContact.lastName,
            email: action.trustedContact.email,
          },
        },
      })
      break
    }

    case 'action.employment-status/change': {
      draft.employment = action.employment
      break
    }

    case 'action.employment-status/submit': {
      if (state.employment === 'EMPLOYED') {
        draft.employer = {
          name: state.employer?.name,
          role: state.employer?.role,
          industry: state.employer?.industry,
          address: state.employer?.address,
        }
        command.push({
          type: 'command.user/sync',
          body: {
            employment: {
              status: state.employment,
            },
          },
        })
      } else if (state.employment === 'SELF_EMPLOYED') {
        draft.employer = {
          name: 'Self',
          role: state.employer?.role,
          industry: state.employer?.industry,
          address: undefined,
        }
        command.push({
          type: 'command.user/sync',
          body: {
            employment: {
              status: state.employment,
              manual_employer_information: {
                employer_address: null,
              },
            },
          },
        })
      } else {
        draft.employer = {
          name: undefined,
          role: undefined,
          industry: undefined,
          address: undefined,
        }
        command.push({
          type: 'command.user/sync',
          body: {
            employment: {
              status: state.employment,
              manual_employer_information: null,
            },
            occupation: null,
          },
        })
      }

      break
    }

    case 'action.employer/change': {
      draft.employer = {
        ...action.employer,
      }
      break
    }

    case 'action.employer/submit': {
      command.push({
        type: 'command.user/sync',
        body: {
          employment: {
            manual_employer_information: {
              employer_name: state.employer?.name,
            },
          },
          occupation: {
            title: state.employer?.role,
            industry: state.employer?.industry,
          },
        },
      })
      break
    }

    case 'action.employer-address/submit': {
      command.push({
        type: 'command.user/sync',
        body: {
          employment: {
            manual_employer_information: {
              employer_address: state.employer?.address,
            },
          },
        },
      })
      break
    }

    case 'action.marital-status/change': {
      draft.maritalStatus = action.status
      break
    }

    case 'action.marital-status/submit': {
      if (state.maritalStatus) {
        command.push({
          type: 'command.user/sync',
          body: {
            tax_profile: {
              marital_status: state.maritalStatus,
            },
          },
        })
      }
      break
    }

    case 'action.affiliations/change': {
      draft.affiliations = [...action.affiliations]
      break
    }

    case 'action.affiliations/submit': {
      command.push({
        type: 'command.user/sync',
        body: {
          affiliations: {
            ...(state.affiliations?.includes('NONE')
              ? {
                  is_finra_affiliated: false,
                  is_politically_exposed: false,
                  is_family_politically_exposed: false,
                }
              : {
                  is_finra_affiliated: state.affiliations?.includes('FINRA'),
                  is_politically_exposed: state.affiliations?.includes(
                    'POLITICALLY_EXPOSED'
                  ),
                  is_family_politically_exposed: state.affiliations?.includes(
                    'POLITICALLY_EXPOSED'
                  ),
                }),
          },
        },
      })
      break
    }

    case 'action.us-status/change': {
      draft.usStatus = action.status
      delete draft.taxProfile?.countryOfTaxResidence
      delete draft.taxProfile?.taxIdType
      delete draft.taxProfile?.taxId
      break
    }

    case 'action.us-status/submit': {
      command.push({
        type: 'command.user/sync',
        body: {
          identity: {
            residence_status: draft.usStatus,
          },
        },
      })
      break
    }

    case 'action.legal-address/change': {
      if (action.address) {
        draft.address = {
          ...action.address,
        }
      } else {
        draft.address = undefined
      }
      break
    }

    case 'action.legal-address/submit': {
      command.push({
        type: 'command.user/sync',
        body: {
          identity: {
            address: { ...draft.address },
          },
        },
      })
      break
    }

    case 'action.external-account/change': {
      draft.externalAccountId = action.account
      break
    }

    case 'action.kyc.personal-info/submit': {
      draft.personalInfo = {
        ...(action.firstName && { firstName: action.firstName }),
        ...(action.lastName && { lastName: action.lastName }),
        ...(action.birthday && { birthday: action.birthday }),
        ...(action.ssn && { ssn: action.ssn }),
      }
      command.push({
        type: 'command.user/sync',
        body: {
          identity: {
            ...(action.firstName && { first_name: action.firstName }),
            ...(action.lastName && { last_name: action.firstName }),
            ...(action.birthday && {
              date_of_birth: toStringDate(action.birthday),
            }),
          },
          ...(action?.ssn &&
            action.ssn !== '*********' && {
              tax_profile: {
                tax_id: formatSSN(action.ssn),
                country_of_tax_residence: 'USA',
                tax_id_type: 'USA_SSN',
              },
            }),
        },
      })
      break
    }

    case 'action/select-account': {
      draft.portfolioType = action.portfolioType
      break
    }

    case 'action/change-account': {
      draft.accountType = action.accountType
      draft.portfolioType = action.portfolioType
      draft.accountId = action.accountId
      draft.accountList = action.accountList
      draft.sleeveType = action.sleeveType

      if (['IRA_TRADITIONAL', 'IRA_ROTH'].includes(action.accountType)) {
        draft.settings.investment_plan.contribution = 125
      }

      if (action.portfolioType === 'HIGH_YIELD') {
        draft.riskLevel = 0
      }

      if (action.participantList) {
        const participantMap: Record<string, Partial<Atomic.Participant>> = {}
        const clientServerIdMap: Record<string, string> = {}

        action.participantList.forEach((participant) => {
          const clientId = uniqueId()
          participantMap[clientId] = cloneDeep(participant)
          clientServerIdMap[clientId] = participant.id
        })

        draft.participantMap = participantMap
        draft.clientServerIdMap = clientServerIdMap
      }

      break
    }

    case 'action/add-primary-beneficiary': {
      draft.participantMap[action.clientId] = action.participant
      draft.clientServerIdMap[action.clientId] = action.participant.id!
      break
    }

    case 'action/delete-beneficiary': {
      delete draft.participantMap[action.clientId]
      command.push({
        type: 'command.participant/delete',
        serverId: draft.clientServerIdMap[action.clientId],
      })
      break
    }

    case 'action/change-beneficiary-relationship': {
      if (state.participantMap[action.clientId]) {
        if (!draft.participantMap[action.clientId].person) {
          draft.participantMap[action.clientId].person = {}
        }
        draft.participantMap[action.clientId].person!.relationship =
          action.relationship as Atomic.ParticipantRelationship

        command.push({
          type: 'command.participant/sync',
          serverId: state.clientServerIdMap[action.clientId],
          body: {
            person: {
              relationship:
                action.relationship as Atomic.ParticipantRelationship,
            },
          },
        })
      }
      break
    }

    case 'action/change-beneficiary-identity': {
      if (state.participantMap[action.clientId]) {
        draft.participantMap[action.clientId] = {
          ...draft.participantMap[action.clientId],
          ownership_percentage: action.ownershipPercentage,
          person: {
            ...draft.participantMap[action.clientId]?.person,
            first_name: action.firstName,
            last_name: action.lastName,
            date_of_birth: action.birthDate,
          },
        }
        command.push({
          type: 'command.participant/sync',
          serverId: state.clientServerIdMap[action.clientId],
          body: {
            ownership_percentage: action.ownershipPercentage,
            person: {
              first_name: action.firstName,
              last_name: action.lastName,
              date_of_birth: action.birthDate,
            },
          },
        })
      }
      break
    }

    case 'action/change-beneficiary-contacts': {
      if (state.participantMap[action.clientId]) {
        draft.participantMap[action.clientId] = {
          ...draft.participantMap[action.clientId],
          person: {
            ...draft.participantMap[action.clientId]?.person,
            email: action.email,
          },
        }
        command.push({
          type: 'command.participant/sync',
          serverId: state.clientServerIdMap[action.clientId],
          body: {
            person: {
              email: action.email,
            },
          },
        })
      }
      break
    }
  }

  return [finishDraft(draft), command]
}

export function createProcess(
  userId: string,
  fetch: ReturnType<typeof useFetch>
) {
  return function process(cmd: Command, dispatch: Dispatch<Action>) {
    switch (cmd.type) {
      case 'command.user/sync': {
        fetch('/users/' + userId, {
          method: 'PATCH',
          body: JSON.stringify(cmd.body),
        })
          .then(() => {
            console.debug('command.user/sync: success')
          })
          .catch((error: any) => {
            console.debug('command.user/sync', error)
          })
        break
      }
      case 'command.sleeve/sync': {
        upsertSleeve(
          userId,
          cmd.riskLevel,
          cmd.accountType,
          cmd.sleeveType,
          cmd.portfolioType !== 'HIGH_YIELD'
        )(fetch)
          .then(([account, sleeve]) => {
            console.debug('command.sleeve/sync: success')
            return fetch('/accounts?userId=' + userId).then((rsp) => {
              dispatch({
                type: 'action/change-account',
                accountId: account.account_id,
                accountType: account.account_type,
                portfolioType: cmd.portfolioType,
                accountList: rsp.elements ?? [],
                sleeveType: sleeve.type,
              })
            })
          })
          .catch((error: any) => {
            console.debug('command.sleeve/sync', error)
          })
        break
      }

      case 'command.sip/sync': {
        upsertSIP(
          userId,
          cmd.accountType,
          {
            recurringContribution: {
              contribution: cmd.contribution,
              schedule: cmd.schedule,
            },
            externalAccountId: cmd.externalAccountId,
            riskLevel: cmd.riskLevel ?? DEFAULT_RECOMMENDED_RISK_LEVEL,
          },
          cmd.sleeveType,
          cmd.portfolioType !== 'HIGH_YIELD'
        )(fetch)
          .then(() => {
            console.debug('command.sip/sync: success')
          })
          .catch((error: any) => {
            console.debug('command.sip/sync', error)
          })
        break
      }

      case 'command.sip/remove': {
        deleteIfAvailableSIP(
          userId,
          cmd.accountType,
          cmd.sleeveType,
          cmd.portfolioType !== 'HIGH_YIELD'
        )(fetch)
          .then(() => {
            console.debug('command.sip/remove: success')
          })
          .catch((error: any) => {
            console.debug('command.sip/remove', error)
          })
        break
      }

      case 'command.participant/sync': {
        fetch(`/participants/${cmd.serverId}`, {
          method: 'PATCH',
          body: JSON.stringify(cmd.body),
        })
          .then(() => {
            console.debug('command.participant/sync: success')
          })
          .catch((error) => {
            console.debug('command.participant/sync', error)
          })
        break
      }

      case 'command.participant/delete': {
        fetch(`/participants/${cmd.serverId}`, {
          method: 'DELETE',
        })
          .then(() => {
            console.debug('command.participant/delete: success')
          })
          .catch((error) => {
            console.debug('command.participant/delete', error)
          })
        break
      }
    }
  }
}
