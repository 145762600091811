import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
// @ts-ignore
import { startTransition } from 'react'
import { useHistory } from 'react-router-dom'
import useSaveOnboardingState from 'shared/onboarding/useSaveOnboardingState'
import useSettings from 'shared/useSettings'
import usePostMessageChannel from 'shared/usePostMessageChannel'
import useSession, { useSessionUpgrade } from 'shared/useSession'
import useFeature from './useFeature'
import { OnboardingState } from 'shared/onboarding/useOnboardingState'
import createVerifications from 'shared/onboarding/createVerifications'
import confirmOnboarding from 'shared/onboarding/confirmOnboarding'
import { makePayload, usePageAnalyticsState } from './usePageAnalytics'
import useFetch from './useFetch'
import { isEnabled, Step } from './steps'
import { useSWRConfig } from 'swr'

export const OnboardingCompleteInProgressContext = createContext(false)

interface OnboardingRouter {
  goBack: (
    currentRoute: string,
    state: OnboardingState
  ) => undefined | (() => void)
  goNext: (currentRoute: string, state: OnboardingState) => () => void
  resetError: () => void
  error?: Error
}

const OnboardingNavigationContext = createContext<OnboardingRouter>({
  goBack: () => () => undefined,
  goNext: () => () => undefined,
  resetError: () => undefined,
})

export default function useOnboardingNavigation() {
  return useContext(OnboardingNavigationContext)
}

export function OnboardingNavigationProvider(props: {
  steps: Step[]
  children: ReactNode
}) {
  const history = useHistory()
  const settings = useSettings()
  const session = useSession()
  const fetch = useFetch()
  const upgradeSession = useSessionUpgrade()
  const saveOnboardingState = useSaveOnboardingState()
  const actions = usePostMessageChannel()
  const steps = props.steps
  const [error, setError] = useState<Error | undefined>()
  const shouldDisableOnboardingConfirmation = useFeature(
    'disable-onboarding-confirmation'
  )
  const enabledOnBoardingConfirmErrors = useFeature(
    'dev-enable-onboarding-confirm-errors'
  )
  const [inProgress, setInProgress] = useState(false)
  const analytics = usePageAnalyticsState()
  const { cache } = useSWRConfig()

  const goBack = useCallback(
    (currentRoute: string, state: OnboardingState) => {
      const routes = steps
        .filter((step) => isEnabled(step, state, settings))
        .map((step) => step.path)

      // Don't show back button if it's first route
      if (routes[0] === currentRoute) {
        return undefined
      }

      // Don't show back button on connect account screen when it's one of last steps
      const connectAccountIndex = routes.indexOf('/connect-account')
      if (
        currentRoute === '/connect-account' &&
        connectAccountIndex >= routes.length - 2
      ) {
        return undefined
      }

      // Don't show back button on wire details screen when it's one of last steps
      const wireDetailsIndex = routes.indexOf('/wire-details')
      if (
        currentRoute === '/wire-details' &&
        wireDetailsIndex >= routes.length - 2
      ) {
        return undefined
      }

      return () => history.goBack()
    },
    [history, steps, settings]
  )

  const confirm = useCallback(
    async (account: Atomic.Account) => {
      if (session.sessionType === 'onboarding') {
        const verification = await createVerifications(
          session.userId,
          enabledOnBoardingConfirmErrors
        )(fetch)
        if (verification?.status === 'processing') {
          await confirmOnboarding(
            session.userId,
            account.account_id,
            account.account_type === 'INDIVIDUAL',
            verification?.status,
            enabledOnBoardingConfirmErrors
          )(fetch)
        }
      } else {
        await confirmOnboarding(
          session.userId,
          account.account_id,
          account.account_type === 'INDIVIDUAL',
          'processing',
          enabledOnBoardingConfirmErrors
        )(fetch)
      }
    },
    [session, fetch, enabledOnBoardingConfirmErrors]
  )

  const goNext = useCallback(
    (currentRoute: string, state: OnboardingState) => {
      return () => {
        const routes = steps
          .filter((step) => isEnabled(step, state, settings))
          .map((step) => step.path)
        const index = routes.indexOf(currentRoute)

        // Next route is not defined
        if (routes.length < index + 1) {
          return console.warn('Unknown next route for ' + currentRoute)
        }

        // Save onboarding state
        if (isTimeToFinishOnboarding(routes, currentRoute)) {
          setInProgress(true)
          return saveOnboardingState()
            .then(({ account }) => {
              if (shouldDisableOnboardingConfirmation) {
                // Don't verify, confirm and upgrade session.
                return
              }
              return confirm(account).then(() => {
                // clean cache before moving to dashboard
                for (let key of cache.keys()) {
                  console.log('cache.delete:', key)
                  cache.delete(key)
                }
              })
            })
            .catch((error: any) => {
              // Show special message about insufficient external account balance
              if (error?.code === 'TR0004') {
                setError(
                  new Error(
                    'You have successfully onboarded however, the initial contribution for your transaction failed because the selected linked account does not have enough balance. You can link a different external account to your contribution plan at any point.'
                  )
                )
              } else {
                throw error
              }
            })
            .then(() => {
              // Handle last step redirect
              setInProgress(false)
              if (index === routes.length - 1) {
                actions.send({
                  type: 'ANALYTICS',
                  subtype: 'ONBOARDING_SUCCESS',
                  payload: makePayload(analytics, {
                    dropped_off: false,
                  }),
                })
                if (settings.shouldCloseAfterOnboarding) {
                  return actions.exit()
                } else {
                  return startTransition(() => {
                    upgradeSession('dashboard')
                  })
                }
              }
              // Go to next page
              history.push(routes[index + 1] || currentRoute)
            })
            .catch((error) => {
              setInProgress(false)
              console.log(error)
              setError(
                new Error(
                  'Unable to complete onboarding, some inputs were incorrect or missing.'
                )
              )
            })
        }

        // Handle last step redirect
        if (index === routes.length - 1) {
          actions.send({
            type: 'ANALYTICS',
            subtype: 'ONBOARDING_SUCCESS',
            payload: makePayload(analytics, {
              dropped_off: false,
            }),
          })
          if (settings.shouldCloseAfterOnboarding) {
            return actions.exit()
          } else {
            return startTransition(() => {
              upgradeSession('dashboard')
            })
          }
        }

        // Go to next page
        history.push(routes[index + 1] || currentRoute)
      }
    },
    [
      steps,
      history,
      settings,
      actions,
      saveOnboardingState,
      upgradeSession,
      analytics,
      shouldDisableOnboardingConfirmation,
      confirm,
      cache,
    ]
  )

  const resetError = useCallback(() => setError(undefined), [])

  const ctx = useMemo(
    () => ({
      goBack,
      goNext,
      error,
      resetError,
    }),
    [goBack, goNext, error, resetError]
  )

  return (
    <OnboardingNavigationContext.Provider value={ctx}>
      <OnboardingCompleteInProgressContext.Provider value={inProgress}>
        {props.children}
      </OnboardingCompleteInProgressContext.Provider>
    </OnboardingNavigationContext.Provider>
  )
}

function isTimeToFinishOnboarding(
  routes: Array<string | undefined>,
  currentRoute: string
) {
  if (
    routes.includes('/sign-agreement') &&
    currentRoute === '/sign-agreement'
  ) {
    return true
  }

  if (
    routes.includes('/account-setup/sign-agreement') &&
    currentRoute === '/account-setup/sign-agreement'
  ) {
    return true
  }

  const onboardingRoutes = routes.filter(
    (route) =>
      route !== '/onboarding-success' &&
      route !== '/connect-account' &&
      route !== '/kyc/retry'
  )
  const index = onboardingRoutes.indexOf(currentRoute)
  return index === onboardingRoutes.length - 1
}
