import { forwardRef, ReactNode } from 'react'
import { Info } from '@phosphor-icons/react'
import classNames from 'classnames'

interface HeaderProps {
  title: string | ReactNode
  titleIcon?: JSX.Element
  description?: ReactNode
  className?: string
  infoIcon?: boolean
  onInfoIconClick?: () => void
}

const Header = forwardRef<HTMLDivElement, HeaderProps>((props, ref) => {
  const { title, description, titleIcon, infoIcon, onInfoIconClick } = props
  const className = classNames('Header', props.className)

  return (
    <div className={className}>
      <div className="Header-title-wrapper">
        <h1 ref={ref} className="Header-title">
          {title}
        </h1>
        {infoIcon && (
          <button
            className="Header-info-icon"
            type="button"
            onClick={onInfoIconClick}
          >
            <Info className="h-5 w-5 text-primary100" />
          </button>
        )}
        {titleIcon}
      </div>
      {description && <p className="Header-description">{description}</p>}
    </div>
  )
})

export default Header
