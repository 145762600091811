import { useCallback, createContext, useContext, ReactNode } from 'react'
import createBaseURL from './createBaseURL'
import useFeature from './useFeature'
import useSettings from './useSettings'
import useSession, { useSessionRefresh } from './useSession'
import * as Sentry from '@sentry/react'
import { z } from 'zod'

type FetchFn = <T = any>(url: any, options?: any) => Promise<T>

const FetchContext = createContext<FetchFn>(
  (url, options): Promise<any> => Promise.resolve(null)
)

// NOTE: emulation of HTTP 401
let i = 0

// Here is an example on how to use `useFetch` for mutation
/*
  function TxReport({ id }) {
    const createNewTx = useFetch('/api/tx', {
      method: 'POST',
      body: JSON.stringify({
        type: 'DEPOSIT',
        from: 'abcd-12324-3'
      })
    })

    const { execute } = useAsync(createNewTx)

    return (
      <div>
        <button onClick={() => execute()}>Deposit</button>
      </div>
    )
  }
*/

const ErrorResponseSchema = z.object({
  code: z.string(),
  trace_id: z.string(),
  developer_message: z.string().optional(),
})

type ErrorResponse = z.infer<typeof ErrorResponseSchema>

export default function useFetch(path?: any, options?: any) {
  const fetchFn = useContext(FetchContext)
  const boundedFetchFn = useCallback(
    (callPath?: any, callOptions?: any) => {
      // Arguments provided to bounded fetch function overrides useFetch hook arguments
      if (callPath) {
        return fetchFn(callPath, callOptions)
      } else {
        return fetchFn(path, options)
      }
    },
    [fetchFn, path, options]
  )

  return boundedFetchFn
}

interface FetchProviderProps {
  children: ReactNode
}

export function FetchProvider(props: FetchProviderProps) {
  const session = useSession()
  const { mode, cluster, _override_api } = useSettings()
  const base = createBaseURL(mode, cluster, _override_api)
  const refreshSession = useSessionRefresh()
  const shouldSimulateExpiredSession = useFeature(
    'dev-simulate-expired-session'
  )

  const fetchFn = useCallback(
    function (path: any, options: any) {
      const url = new URL(path, base)

      const fetchOptions: any = {
        ...options,
        headers: {
          ...(options?.headers ?? {}),
          'Atomic-Session': session.sessionToken,
        },
      }

      return fetch(url.toString(), fetchOptions)
        .then((rsp) => {
          // NOTE: emulation of HTTP 401, debugging
          if (
            shouldSimulateExpiredSession &&
            i === 0 &&
            url.toString() === `${base}/principles?`
          ) {
            i++
            throw new HTTPError(401)
          }

          if (!rsp.ok) {
            return rsp.json().then((data) => {
              throw new APIError(
                ErrorResponseSchema.parse(data),
                url.toString()
              )
            })
          }

          return rsp.json()
        })
        .catch((error) => {
          if (error instanceof APIError) {
            console.log(
              `Unexpected API response, open a support ticket to help@atomicvest.com with trace_id: ${error.traceId}`
            )
            Sentry.captureException(error, {
              extra: {
                message: error.message,
                traceId: error.traceId,
                url: error.url,
              },
            })
          }

          if (error instanceof TypeError) {
            throw new NetworkError('Network connection lost')
          }

          // We can request new one-time token when session expires
          if (error instanceof HTTPError && error.status === 401) {
            refreshSession()
          }

          throw error
        })
    },
    [base, session, refreshSession, shouldSimulateExpiredSession]
  )

  return (
    <FetchContext.Provider value={fetchFn}>
      {props.children}
    </FetchContext.Provider>
  )
}

export class HTTPError extends Error {
  status: number

  constructor(status: number, msg?: any) {
    super(`HTTP ${status} ${msg || ''}`)
    this.name = 'HTTPError'
    this.status = status
    Object.setPrototypeOf(this, HTTPError.prototype)
  }
}

export class APIError extends Error {
  code: string
  traceId: string
  url: string

  constructor(rsp: ErrorResponse, url: string) {
    super(rsp.developer_message ?? rsp.trace_id)
    this.name = 'APIError'
    this.code = rsp.code
    this.traceId = rsp.trace_id
    this.url = url
    Object.setPrototypeOf(this, APIError.prototype)
  }
}

export class NetworkError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'NetworkError'
    Object.setPrototypeOf(this, APIError.prototype)
  }
}
