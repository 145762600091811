import classNames from 'classnames'
import { ReactNode } from 'react'

interface Props {
  className?: string
  children: ReactNode
}

export default function LayoutCenter(props: Props) {
  const className = classNames(
    'pb-16 tbs:pb-0 tbm:flex tbm:h-full tbm:overflow-y-auto',
    props.className
  )
  return (
    <div className={className}>
      <div className="tbm:mx-auto tbm:my-auto tbm:flex tbm:flex-col tbm:items-center">
        {props.children}
      </div>
    </div>
  )
}
