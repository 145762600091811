export default function Spinner(props: { classNames?: string }) {
  return (
    <div className={`lds-spinner ${props.classNames}`}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  )
}
