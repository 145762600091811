import { createAccount, fetchAccountByType } from './upsertAccount'
import { DEFAULT_RECOMMENDED_RISK_LEVEL } from '../../views/retail/pages/RecordRiskLevelPage'

export default function upsertSleeve(
  userId: string,
  riskLevel: number = DEFAULT_RECOMMENDED_RISK_LEVEL,
  accountType: Atomic.AccountType,
  sleeveType: Atomic.SleeveType = 'CUSTOM_INDEXING',
  enforceCustomPortfolioSleeve: boolean
) {
  return async (fetch: Function): Promise<[Atomic.Account, Atomic.Sleeve]> => {
    const account: Atomic.Account = await fetchAccountByType(
      userId,
      accountType
    )(fetch)

    // Account is already created
    if (account) {
      const sleeve = await fetchSleeveByType(
        account.account_id,
        sleeveType,
        enforceCustomPortfolioSleeve
      )(fetch)
      if (!sleeve) {
        return createSleeve({
          accountId: account.account_id,
          riskLevel,
          type: sleeveType,
        })(fetch).then((sleeve: any) => [account, sleeve])
      }
      return updateSleeve(
        account.account_id,
        sleeve.sleeve_id,
        riskLevel
      )(fetch).then((sleeve: any) => [account, sleeve])
    }

    // No account for given type has been created
    const createdAccount: Atomic.Account = await createAccount({
      userId,
      riskLevel,
      type: accountType,
    })(fetch)

    return fetchSleeveByType(
      createdAccount.account_id,
      sleeveType,
      enforceCustomPortfolioSleeve
    )(fetch).then((sleeve: any) => [createdAccount, sleeve])
  }
}

export function fetchSleeveByType(
  accountId: string,
  type: Atomic.SleeveType,
  enforceCustomPortfolioSleeve: boolean
) {
  return async (fetch: Function) => {
    const rsp = await fetch(
      `/accounts/${encodeURIComponent(accountId)}/sleeves`
    )
    const sleeves = rsp.elements ?? []
    const targetSleeve = sleeves.find((sleeve: any) => sleeve.type === type)
    const customPortfolioSleeve = sleeves.find(
      (sleeve: any) => sleeve.type === 'CUSTOM_PORTFOLIO'
    )
    return enforceCustomPortfolioSleeve
      ? customPortfolioSleeve ?? targetSleeve
      : targetSleeve
  }
}

export function createSleeve(data: {
  accountId: string
  riskLevel: number
  type: Atomic.SleeveType
}) {
  return (fetch: Function) => {
    return fetch(`/accounts/${encodeURIComponent(data.accountId)}/sleeves`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        type: data.type,
        preferences: {
          risk_score: data.riskLevel,
        },
      }),
    })
  }
}

function updateSleeve(accountId: string, sleeveId: string, riskLevel: number) {
  return (fetch: Function) => {
    return fetch(`/accounts/${accountId}/sleeves/${sleeveId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        preferences: {
          risk_score: riskLevel,
        },
      }),
    })
  }
}
